import React, { FC, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { connect } from "react-redux";
import { fr } from "date-fns/locale";
import { convert, moveDay } from "../librairies/dateFunction";
import Loading from "../spinner/spinner";
import PlanificationResult from "./PlanificationResult";
import { PostDataPlanification } from "../../store";
type dateData = {
  startDate: Date;
  endDate: Date;
  key: string;
};
const Planification: FC<{ data: any; fetchData: any }> = ({
  data,
  fetchData,
}) => {
  // https://designcode.io/react-hooks-handbook-usestate-hook
  // https://designcode.io/react-hooks-handbook-fetch-data-from-an-api
  //const [labels , setLabels] = useState([""]);

  const [date, setDate] = useState<dateData>({
    startDate: moveDay(-7),
    endDate: new Date(),
    key: "selection",
  });
  console.log(data);
  const getData = () => {
    fetchData(
      convert(date.startDate.toString()),
      convert(date.endDate.toString())
    );
  };
  return (
    <>
      <div className="container-fluid blck-comparatif">
        <h3 className="text-center title">PLANIFICATION</h3>
        <div className="row">
          <div className="col-md-5" style={{ margin: "20px auto" }}>
            <div className="card">
              <div className="card-body">
                <h2 className="text-center title-dateRange">
                  Choisir la période
                </h2>
                <DateRangePicker
                  onChange={(item: any) => setDate(item.selection)}
                  moveRangeOnFirstSelection={false}
                  ranges={[date]}
                  maxDate={new Date()}
                  locale={fr}
                />
              </div>
              <div></div>
            </div>
            {!data.loading ? (
              <button className="btn-grad" onClick={getData}>
                Valider
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {data.loading ? (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card ">
                  <div className="card-body ">
                    <Loading />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {data.error !== null ? (
        <h2 className="alert alert-danger" role="alert">
          Une Erreur est survenue
        </h2>
      ) : data.loading ? (
        <div className="col-12 col-lg-12 col-xl-12 col-md-12 content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card ">
                  <div className="card-body ">
                    <Loading />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : data.planificationData.labels ? (
        <div className="col-12 col-lg-12 col-xl-12 col-md-12">
          <div className="max-width-mdcent">
            {data.planificationData.labels.length > 0 ? (
              <PlanificationResult data={data.planificationData} />
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    data: state.planification,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (startDate: string, endDate: string) =>
      dispatch(PostDataPlanification(startDate, endDate)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Planification);
