import React, { Component } from 'react'

class Loading extends Component {
  render() {
    return (
        <div className="spinner-border" role="status" style={{display: "block",margin: "0 auto", width: "50px",height: "50px"}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  }
}

export default Loading
