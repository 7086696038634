import React from 'react';
import imageloading from "../../assets/img/loading.jpg";

const Loader=()=>{
    const imgStyle = {
        height:'10%',
        width:'10%', 
        justifyContent:'center',
        alignItems:'center'
    }
    return (
        <>
            <div className='text-center'>
                <img src={imageloading} alt="loading" style={imgStyle}/>
            </div>
        </>
    )
}

export default Loader;