import { FC, ReactElement } from "react";
import React from "react";
import { Line } from "react-chartjs-2";
import { convertSecToH } from "../librairies/dateFunction";
import { optionsComparatifDetail } from "../../Tools/app";

type chartData = {
  calls: string;
  day: string;
  acd: string;
};
const ComparatifResult: FC<{
  dataTMC: object;
  titre: string;
  titre2: string;
  dataCALLS: object;
  dataCALLSDetails: Array<any>
}> = ({ dataTMC, titre, titre2, dataCALLS, dataCALLSDetails }): ReactElement => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="container user-acddating col-md-10">
          <div className="card-body">
            <h1>{titre}</h1>
            <div className="row">
              <Line
                type="line"
                data={dataTMC}
                options={{
                  spanGaps: true,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context: {
                          raw: chartData;
                          dataset: { type: string; label: string };
                          formattedValue: string;
                        }) => {
                          return `date : ${context.raw.day} | calls : ${context.raw.calls} | tmc : ${context.raw.acd}`;
                        },
                      },
                    },
                  },
                  scales: {
                    y: {
                      title: {
                        display: true,
                        text: "Durée",
                      },
                      ticks: {
                        callback: function (
                          value: any,
                          index: any,
                          values: any
                        ) {
                          return `${convertSecToH(value)} [${value}secs]`;
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="card-body">
            <h1>{titre2}</h1>
            <div className="row">
              <Line
                type="line"
                data={dataCALLS}
                options={{
                    spanGaps: true,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (context: { raw: chartData, dataset: { type: string, label: string }, formattedValue: string }) => {
                            return `date : ${context.raw.day} | calls : ${context.raw.calls} | tmc : ${context.raw.acd}`;
                          }
                        }
                      }
                    },
                    scales: {
                      y: {
                        title: {
                          display: true,
                          text: 'Nombre d\'appels'
                        }
                      },
                    }
                  }}
              />
            </div>
          </div>
          {
              dataCALLSDetails.map((q: any, index: any) => {
                const calls: object[] = [];
                const tmc: object[] = [];
                return <>
                  {
                    q.data ? <div className="card-body" key={index + "dataCall"}>
                      <h3>{q.label}</h3>
                      <div className="row">
                        {
                          q.data.forEach((d: any) => {
                            calls.push({ x: d.day, y: d.calls })
                            tmc.push({ x: d.day, y: d.time })
                          })
                        }
                        {
                          <Line
                            key={index + "comparaison"}
                            type="bar"
                            data={{
                              datasets: [
                                {
                                  type: 'line',
                                  label: 'TMC',
                                  borderColor: '#438ca2',
                                  borderWidth: 2,
                                  fill: true,
                                  data: tmc,
                                },
                                {
                                  type: 'line',
                                  label: 'calls',
                                  borderColor: '#3b36d2',
                                  borderWidth: 2,
                                  fill: true,
                                  data: calls,
                                  yAxisID: "calls_y_axis",
                                },
                              ],
                            }}
                            options={optionsComparatifDetail}
                          />
                        }
                      </div>
                    </div>
                      : null
                  }
                </>
              })
            }
        </div>
      </div>
      </div>
    </>
  );
};
export default React.memo(ComparatifResult);
