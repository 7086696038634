/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { DateRangePicker } from 'react-date-range';
import CloseIcon from '@material-ui/icons/Close';
import CodeIcon from '@material-ui/icons/Code';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loading from '../spinner/spinner';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import { setLocalStorage, getLocalStorage } from '../../Tools/utility';
import { format } from 'date-fns';
import { Sort } from '@material-ui/icons';



const drawerWidth = 500;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginRight: -drawerWidth,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	}),
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate());
	const [services, setService] = React.useState<string>('');
	const [start_date, setStartDate] = useState<string>((new Date()).toISOString())
	const [end_date, setEndDate] = useState<string>((new Date()).toISOString())
	const [showedData, setShowedData] = React.useState([] as any[]);
	const [showedAcd, setShowedAcd] = useState<string>('');
	const [datas, setdatas] = React.useState([] as any[]);
	let count:number = 0;
	const [durations, setDurations] = useState<number>(0);
	const [selectionRange, setSelectionRange] = React.useState({
		startDate: yesterday,
		endDate: yesterday,
		key: 'selection',
	});
	const [isDescAcd, setIsDescAcd] = useState<boolean>(true);
	const [isDescStart, setIsDescStart] = useState<boolean>(true);
	const [isDescAgent, setIsDescAgent] = useState<boolean>(true);

	const downloadCSV = () => {
		const headers = Object.keys(showedData[0]);
		const csvContent = "data:text/csv;charset=utf-8," +
			headers.join(',') +
			'\n' +
			showedData.map(item => headers.map(header => item[header]).join(',')).join('\n');

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `Report_${services} ${format(new Date(), 'HHmmss-ddMMyyyy')}.csv`);
		document.body.appendChild(link);
		link.click();
	}
	const handleSelect = async (ranges: any) => {
		setSelectionRange(ranges.selection);
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);
	};
	const fetchTable = async () => {
		setdatas([])
		setShowedData([])
		try {
			const startDate = new Date(start_date).toISOString().split('T')[0];
			const endDate = new Date(end_date).toISOString().split('T')[0];
			if(startDate!== start_date && endDate !== end_date){
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/didstats/${startDate}/${endDate}`);
				if (!response.ok) throw new Error('Not founds');
				const data = await response.json();
				setdatas(data);
				setShowedData(data)
				const acd = (data.reduce((total:any, currentItem:any) => {
					const [hour,minutes, seconds] = currentItem.ts_servicing.split(':');
					const time = parseInt(hour) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
					return total + time;
				}, 0)/data.length)
				const h = Math.floor(acd / 3600);
				const m = Math.floor(acd % 3600 / 60)
				const s = Math.floor(acd % 3600 % 60)
				setShowedAcd(`${h>=10?h:`0${h}`}:${m>=10?m:`0${m}`}:${s>=10?s:`0${s}`}`)
			}
		} catch (er) {
			console.log(er)
			throw er
		}
	}
	const theme = useTheme();
	const [open, setOpen] = React.useState(true);

	const [lists, setlists] = useState([]);
	let dids_cookie = getLocalStorage('dids_cookie');
	
	useEffect(() => {
		const fetchData = async () => {
			count++;
			if (lists.length < 1) {
				if (dids_cookie === '') {
					try {
						const response = await fetch(`${process.env.REACT_APP_API_URL}/api/didstats`);
						if (!response.ok) {
							throw new Error('Dids errors');
						}
						const data = await response.json();
						let keys: any = [];
						Object.keys(data).forEach(key => keys.push(data[key].service));
						setlists(Array.from(new Set(keys)));
						setLocalStorage('dids_cookie', data);
						dids_cookie = getLocalStorage('dids_cookie');
					} catch (error) {
						console.log(error);
					}
				} else {
					let keys: any = [];
					Object.keys(dids_cookie).forEach(key => keys.push(dids_cookie[key].service));
					setlists(Array.from(new Set(keys)));
					fetchTable();
				}
			}
		};
		fetchData();
	}, [lists]); 
	
	const handleService = async(_: any, s: any) => {
		
		const ds = dids_cookie;
		let d = datas.filter(item=> item.service === s);
		setService(s)
		let sm = 0;
		setShowedData(d)
		d.forEach(item=>{
			if(item.duration_hms){
				const dr :number[] = item.duration_hms.split(":").map(Number);
				sm += dr[0]*3600 + dr[1*60] + dr[2];
				console.log(sm)
			}
		})
		const acd = (d.reduce((total:any, currentItem:any) => {
			const [hour,minutes, seconds] = currentItem.ts_servicing.split(':');
			const time = parseInt(hour) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
			return total + time;
		}, 0)/d.length)
		const h = Math.floor(acd / 3600);
		const m = Math.floor(acd % 3600 / 60)
		const sec = Math.floor(acd % 3600 % 60)
		setShowedAcd(`${h>=10?h:`0${h}`}:${m>=10?m:`0${m}`}:${sec>=10?sec:`0${sec}`}`)
		setDurations(sm)
	}

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
						Did stats
					</Typography>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="end"
						onClick={handleDrawerOpen}
						sx={{ ...(open && { display: 'none' }) }}
					>
						<CodeIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div className='text-center'>
				{(() => {
					if (showedData.length > 0) {
						return <>
							<TableContainer>
								<Table style={{ overflow: 'auto', height: '100%', border: '1px solid #ddd' }}>
									<TableHead style={{ position: 'sticky' }}>
										<TableCell style={{ border: '1px solid #ddd' }}>Nom <Sort
											style={{ cursor: 'pointer', float: 'right',color:'black' }}
											onClick={() =>{
												let sort = showedData;
												if(sort.length>1 && isDescAgent) 
													sort = sort.sort((a, b)=> (a.agent) - (b.agent));
												else if (sort.length>1 && !isDescAgent)
													sort = sort.sort((a, b)=> (b.agent) - (a.agent));
												setIsDescAgent(!isDescAgent)
												setShowedData(sort)
											}}
										/></TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>Agent</TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>Début d'appel <Sort
											style={{ cursor: 'pointer', float: 'right',color:'black' }}
											onClick={() =>{
												let sort = showedData;
												if(sort.length>1 && isDescStart) 
													sort = sort.sort((a, b)=> new Date(a.time_start).getTime() - new Date(b.time_start).getTime());
												else if (sort.length>1 && !isDescStart)
													sort = sort.sort((a, b)=> new Date(b.time_start).getTime() - new Date(a.time_start).getTime());;
												console.log(new Date(`1970-01-01T${sort[0].time_start}`).getTime());
												setIsDescStart(!isDescStart)
												setShowedData(sort)
											}}
										/></TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>Fin d'appel</TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>Services</TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>Durée animation agent <Sort 
												style={{ cursor: 'pointer', float: 'right',color:'black' }}
												onClick={() =>{
													let sort = showedData;
													if(sort.length>1 && isDescAcd) 
														sort = sort.sort((a, b)=> new Date(`1970-01-01T${a.ts_servicing}`).getTime() - new Date(`1970-01-01T${b.ts_servicing}`).getTime());
													else if (sort.length>1 && !isDescAcd)
														sort = sort.sort((a, b)=> new Date(`1970-01-01T${a.ts_servicing}`).getTime() + new Date(`1970-01-01T${b.ts_servicing}`).getTime());;
													setIsDescAcd(!isDescAcd)
													setShowedData(sort)
												}}
											/></TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>DID</TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>Numéro appelant</TableCell>
										<TableCell style={{ border: '1px solid #ddd' }}>Status de l'appel</TableCell>
									</TableHead>
									<TableBody>
										{showedData.map(row => (
											<TableRow style={{ border: '1px solid #ddd' }}>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.name}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.agent}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.time_start}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.time_end}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.service}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.ts_servicing}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.did}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.caller_id}</TableCell>
												<TableCell style={{ border: '1px solid #ddd' }}>{row.reason_noanswerdesc}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					}
					else return <Loading></Loading>
				})()}
			</div>

			<Main open={open}>
				<DrawerHeader />
			</Main>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
					},
				}}
				variant="persistent"
				anchor="right"
				open={open}
			>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <CloseIcon /> : <CloseIcon />}
					</IconButton>
				</DrawerHeader>

				<h2 className='text-center'>Selectionner un ou une intervalle de date</h2>
				<DateRangePicker
					ranges={[selectionRange]}
					onChange={handleSelect}
				/>
				<br /><br />
				<Divider />
				<label htmlFor="services">Selectionnez des services</label>
				<Autocomplete
					disablePortal
					id="combo-box-demo"
					options={lists}
					onChange={handleService}
					renderInput={(params) => (
						<TextField {...params} label="services" id='services' />
					)}
				/>
				<br />
				<Divider />
				<br /><br />
				<Divider />
				<button className='btn btn-primary' onClick={fetchTable}>Récupérer les données</button><br />
				<button className='btn btn-primary' onClick={downloadCSV}>Telecharger CSV</button>
				<Divider /> <br /><br />
				<div>
					<p><strong>NB appels: </strong> {showedData.length}</p>
					<p><strong>ACD Agents: </strong> {showedAcd}</p>
				</div>
			</Drawer>
		</Box>
	);
}
