import { ReactElement, useContext, useEffect, useState } from "react";
import Loading from "../spinner/spinner";
import "react-tabs/style/react-tabs.css";
import CheckboxComponent from "../librairies/checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AnalyseLogsTab from "./analyseLogsTab";
import { TokenContext } from "../../App";
const AnalyseLogs = (): ReactElement => {
  const token = useContext(TokenContext);
  const [isLoaded, setIsLoading] = useState<Boolean>(false);
  const [error, setError] = useState<any>(null);
  const [itemsTab, setItemsTab] = useState<Array<any>>([]);
  const [titleSend, setTitleSend] = useState<any>([]);
  const [serveur, setServeur] = useState<string>("");
  const [analyseItem, setAnalyseItem] = useState<any>([]);
  const [checkBoxList, setcheckBoxList] = useState<Array<any>>([
    { label: "Légitimes", checked: false },
    { label: "Suspects", checked: false },
  ]);
  const onChangeCheck = () => {
    checkBoxList.filter((x: any) => x.checked);
  };
  const toggle = (index: any) => {
    const checkList = [
      { label: "Légitimes", checked: false },
      { label: "Suspects", checked: false },
    ];
    checkList.splice(index, 1, {
      label: checkList[index].label,
      checked: !checkList[index].checked,
    });
    const response = checkList
      .filter((x: any) => x.checked)
      .map((el: any) => {
        return el.label;
      });

    setTitleSend(response);
    setcheckBoxList(checkList);
  };
  const handleChange = (event: any) => {
    setServeur(event.target.value);
  };
  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      const dataHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      fetch(process.env.REACT_APP_API_URL + "/api/customer/logs/analyse", dataHeader)
        .then((res) => {
          if (!res.ok) {
            setIsLoading(false);
            setError("ERROR");
            return "ERROR";
          }
          return res.json();
        })
        .then(
          (result) => {
            setItemsTab(result.servers);
            setIsLoading(false);
            setError(null);
          },
          (err) => {
            setIsLoading(true);
            setError(err);
          }
        );
    };
    getData();
  }, [token]);
  const getAnalyse = () => {
    setIsLoading(true);
    const dataHeader = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch(process.env.REACT_APP_API_URL + "/api/customer/logs/analyse?server=" + serveur,
      dataHeader
    )
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          setError("ERROR");
          return "ERROR";
        }
        return res.json();
      })
      .then(
        (result) => {
          setAnalyseItem(result.analyzelog[serveur]);
          setIsLoading(false);
          setError(null);
        },
        (err) => {
          setIsLoading(true);
          setError(err);
        }
      );
  };
  return (
    <div className="container-fluid blck-comparatif">
      <h3 className="text-center title">
        Affichage des logs légitimes et suspects
      </h3>
      <div className="mt-6">

      <div className="row">
        <div
          className="col-12 col-lg-6 text-center col-xl-6 col-md-6"
          style={{ margin: "20px auto" }}
        >
          <div className="row">
            <div className="col-6">
              <FormControl fullWidth>
                <InputLabel
                  className="text-center"
                  style={{ color: "black" }}
                  id="select-label"
                >
                  Choisir le serveur concerné
                </InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={serveur}
                  label="serveur"
                  onChange={handleChange}
                  className="text-center"
                >
                  {itemsTab.map((item: any, ind: number) => {
                    return (
                      <MenuItem
                        key={item + ind}
                        className="text-center"
                        value={item}
                      >
                        {item.toUpperCase()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {
                <button
                  className={
                    !isLoaded && serveur && titleSend.length > 0
                      ? "btn-grad"
                      : "btn-disable"
                  }
                  onClick={() => getAnalyse()}
                >
                  Afficher
                </button>
              }
            </div>
            <div className="col-6">
              <CheckboxComponent
                items={checkBoxList}
                className={"col-md-6"}
                toggle={toggle}
                onChangeCheck={onChangeCheck}
              />
            </div>
          </div>
        </div>
        {error !== null ? (
          <h2 className="alert alert-danger" role="alert">
            Une Erreur est survenue
          </h2>
        ) : isLoaded ? (
          <div className="col-12 col-lg-12 col-xl-12 col-md-12 content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-body ">
                      <Loading />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : analyseItem ? (
          <div className="col-12 col-lg-12 col-xl-12 col-md-12">
            <div className="max-width-mdcent">
              {analyseItem ? (
                <div className="row">
                  {titleSend.length === 1 &&
                  titleSend[0] === "Légitimes" &&
                  analyseItem.matched ? (
                    <AnalyseLogsTab
                      items={analyseItem.matched}
                      serverName={serveur}
                      title={titleSend[0]}
                    />
                  ) : titleSend.length === 1 &&
                    titleSend[0] === "Suspects" &&
                    analyseItem.missed ? (<AnalyseLogsTab
                      items={analyseItem.missed}
                      serverName={serveur}
                      title={titleSend[0]}
                    />) : null}
                </div>
              ) : (
                <h2 className="text-center">Aucun Résultat</h2>
              )}
            </div>
          </div>
        ) : null}
      </div>
      </div>
    </div>
  );
};

export default AnalyseLogs;
