import React, { useContext, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Loading from "../spinner/spinner";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import Player from "../player/player";
import RequalificationForm from "./RequalificationForm";
import { TokenContext } from "../../App";
const Requalif = () => {
  const token = useContext(TokenContext);
  const [tableTitle] = useState<Array<string>>([
    "start_time",
    "caller",
    "did",
    "total",
    "transfert",
    "agent",
    "qualif",
    "requalif",
    "extension",
  ]);
  const [error, setError] = useState<any>(null);
  const [isLoaded, setIsLoading] = useState<Boolean>(false);
  const [items, setItems] = useState<Array<any>>([]);
  const [dateTime, setdateTime] = useState<any>();
  const getCallNonQualif = () => {
    setIsLoading(true);
    const config: RequestInit = {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    };
    fetch(
      process.env.REACT_APP_API_URL +
        "/api/nonqualifiedcalls?date=" +
        moment(dateTime).format("YYYY-MM-DD"),
      config
    )
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          setError("ERROR");
          return "ERROR";
        }
        return res.json();
      })
      .then(
        (result) => {
          if (error !== "ERROR") {
            setItems(result);
            setError(null);
          } else {
            setError("ERROR");
          }
          setIsLoading(false);
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
          setError(err);
        }
      );
  };
  const maxDateDebut = (current: any) => {
    return current.isBefore(moment());
  };
  return (
    <>
      {console.log(error)}
      <div className="container-fluid blck-comparatif">
        <h3 className="text-center title">Requalification</h3>
        <div className="card-body">
          <div className="row">
            <div className=" card content col-md-6 formAcdDateSystem">
              <div className="alert alert-dark" role="alert">
                Choisissez la date
              </div>
              <div className="col-md-4 card-group-item diplay-flex">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  onChange={setdateTime}
                  isValidDate={maxDateDebut}
                  locale="fr-ca"
                  timeFormat={false}
                />
              </div>
              {
                <button
                  className={dateTime && !isLoaded ? "btn-grad" : "btn-disable"}
                  onClick={() => getCallNonQualif()}
                >
                  Afficher
                </button>
              }
            </div>
            {isLoaded ? (
              <div className="content col-md-12">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card ">
                        <div className="card-body ">
                          <Loading />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : error === "ERROR" ? (
              <h2 className="alert alert-danger" role="alert">
                Une Erreur est survenue
              </h2>
            ) : items.length > 0 ? (
              <div className="card col-md-12">
                <div className="card-body ">
                  <h2 className="text-center">Résultat</h2>
                  {items.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {tableTitle.map((el: string) => (
                              <TableCell key={el + "title"}>{el}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {items.map((el: any, index: number) => {
                            return (
                              <>
                                <TableRow key={index + "requalif "}>
                                  <TableCell>{el.start_time}</TableCell>
                                  <TableCell>{el.caller_id}</TableCell>
                                  <TableCell>{el.did}</TableCell>
                                  <TableCell>{el.agent_duration}</TableCell>
                                  <TableCell>
                                    <RequalificationForm
                                      callid={el.uniqueid}
                                      token={token}
                                    />
                                  </TableCell>
                                  <TableCell>{el["3cx_agent_name"]}</TableCell>
                                  <TableCell>{el.qualification}</TableCell>
                                  <TableCell>
                                    {el["3cx_record"] !== "" &&
                                    el["3cx_record"] &&
                                    el["3cx_record"] !== "Indisponible" ? (
                                      <Player src={el["3cx_record"]} />
                                    ) : null}
                                  </TableCell>
                                  <TableCell>
                                    {el["3cx_agent_extension"]}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <h2 className="text-center">Aucun Résultat</h2>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default Requalif;
