import axios from "axios"
import moment from "moment"
import { durationToSeconds } from "../../Tools/utility";
export const getCallsAnalysisData = async (date1:string, date2:string) =>{
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/didstats/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}`;
        const response = await axios.get(url)
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject(new Error('Échec de la connexion : Réponse non valide de l\'API'));
        }
    }catch (error) {
      return Promise.reject(new Error(`Échec de la connexion : ${error}`));
    }
}
export const getVetoCallsAnalysisData = async (date1:string, date2:string) =>{
    try {
        const url = `${process.env.REACT_APP_API_NTX_DATA_ACCESS_URL}/vetodidstats/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}`;
        const response = await axios.get(url)
        
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject(new Error('Échec de la connexion : Réponse non valide de l\'API'));
        }
    }catch (error) {
      return Promise.reject(new Error(`Échec de la connexion : ${error}`));
    }
}

export const getDataVetoStats = async (date1:string, date2:string) =>{
    try {
        const url = `${process.env.REACT_APP_API_NTX_DATA_ACCESS_URL}/vetoStats/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}`;
        const response = await axios.get(url)
        
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject(new Error('Échec de la connexion : Réponse non valide de l\'API'));
        }
    }catch (error) {
      return Promise.reject(new Error(`Échec de la connexion : ${error}`));
    }
}
export const transformDatas =(datas:any)=>{
    const data:any = [[]];
    for (const call of datas) {
        const date = call.time_start.split(' ')[0];
        if(!data[date]) {
            data[date] = [[]];
            data[date][call.service] = [];
        }
        else if (data[date].length > 0){
            const tempData = data[date];
            if(!(call.service in tempData)) 
            {
                data[date][call.service] = [];
            }
        }
        data[date][call.service].push(call)
    }
    return data;
}


export const transformTableData = (datas:any)=>{
    const data:any = [];
    for (const call of datas) {
        const date = call.time_start.split(' ')[0];
        if(!data[date]) {
            data[date] = [[]];
            data[date][call.reason_noanswerdesc] = [];
        }
        else if (data[date].length > 0){
            const tempData = data[date];
            if(!(call.reason_noanswerdesc in tempData)) 
                data[date][call.reason_noanswerdesc] = [];
        }
        data[date][call.reason_noanswerdesc].push(call)
    }
    return data;
}
export const transformTableDataVetoStat = (datas:any)=>{
    const data:any = [];
    for (const call of datas) {
        const date = call.time_start.split(' ')[0];
        if(!data[date]) {
            data[date] = [];
        }
        data[date].push(call)
    }
    return data;
}


export const formatGraphData = (datas:any)=>{
    let newData:any = [];
    Object.keys(datas).map((key)=>{
        newData.push({
            "name": key,
            "Answered": !datas[key].hasOwnProperty("Answered")?0:datas[key]["Answered"].length,
            "Call Failed": !datas[key].hasOwnProperty("Call failed")?0:datas[key]["Call failed"].length,
            "Cancelled": !datas[key].hasOwnProperty("Cancelled")?0:datas[key]["Cancelled"].length,
            "Pool expired": !datas[key].hasOwnProperty("Poll expired")?0:datas[key]["Poll expired"].length,
            "User requested": !datas[key].hasOwnProperty("User requested")?0:datas[key]["User requested"].length,
            "Caller has dropped call": !datas[key].hasOwnProperty("Caller has dropped call")?0:datas[key]["Caller has dropped call"].length,
            "Transfer OK": !datas[key].hasOwnProperty("Transfer OK")?0:datas[key]["Transfer OK"].length,
        });
    })
    return newData;
}
type GraphData = {
    day: string;
    numberOfCalls: number;
    totalDurationServices: number;
    totalDurationWaiting: number;
  };
export const formeDataVeto = (tableauData: any): GraphData[] => {
    if (!tableauData || Object.keys(tableauData).length === 0) {
      return [];
    }
  
    return Object.keys(tableauData).map((key) => {
      let totalDurationServices = 0;
      let totalDurationWaiting = 0;
      let numberOfCalls = tableauData[key].length;
  
      tableauData[key].forEach((call:any) => {
        totalDurationServices += durationToSeconds(call.duration_services || '00:00:00');
        totalDurationWaiting += durationToSeconds(call.duration_waiting || '00:00:00');
      });
  
      // Condition pour ajuster les valeurs de totalDurationServices et totalDurationWaiting
      const adjustedTotalDurationServices = totalDurationServices >= 100 ? totalDurationServices / 100 : 0;
      const adjustedTotalDurationWaiting = totalDurationWaiting >= 100 ? totalDurationWaiting / 100 : 0;
  
      return {
        day: key,
        numberOfCalls,
        totalDurationServices: adjustedTotalDurationServices,
        totalDurationWaiting: adjustedTotalDurationWaiting,
      };
    });
}

export const getAllServices = (datas:any)=>{
    let services:Set<string> = new Set();
    for (const call of datas) {
        services.add(call.service);
    }
    return services;
}