import React, { useEffect } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
Chart.register(ChartjsPluginStacked100); //

const ConnectedTime = () => {
  // https://designcode.io/react-hooks-handbook-usestate-hook
  // https://designcode.io/react-hooks-handbook-fetch-data-from-an-api
  useEffect(() => { }, []);
  return <>
    <div className="container-fluid blck-comparatif">
      <h3 className="text-center title">Temps de connexion</h3>
      <div className="row">
        <div className="col-md-10" style={{ margin: "20px auto" }}>
          <div className="card" >
            <div className="card-body">
              {
                <div>
                  <Bar
                    type="bar"
                    data={{
                      labels: ["Foo", "Bar"],
                      datasets: [
                        { label: "bad", data: [5, 25], backgroundColor: "rgba(244, 143, 177, 0.6)" },
                        { label: "better", data: [15, 10], backgroundColor: "rgba(255, 235, 59, 0.6)" },
                        { label: "good", data: [10, 8], backgroundColor: "rgba(100, 181, 246, 0.6)" }]
                    }}
                    options={{
                      indexAxis: "y",
                      plugins: {
                        stacked100: { enable: true }
                      }
                    }} />
                </div>
              }
            </div>
            <div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>

    </div>
  </>
}
export default ConnectedTime
