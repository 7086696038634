import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';

interface error {
	message: string
}
const columns = [
	{ field: 'id', headerName: 'ID', width: 90 },
	{
	  field: 'titre',
	  headerName: 'TITRE',
	  width: 300,
	  editable: true,
	},
	{
	  field: 'numero',
	  headerName: 'Numéro de Téléphone',
	  width: 200,
	  editable: true,
	},
	{
	  field: 'type',
	  headerName: 'TYPE',
	  width: 200,
	  editable: true,
	}
  ];
const TablesMarquesLeads = (props: any) => {
	const [items, setitems] = useState<Array<any>>([]);
	const [isLoaded, setisLoaded] = useState<Boolean>(false);
	const [error, seterror] = useState<error | any>();

	useEffect(() => {
		function getMarquesLeads() {
			fetch("https://creme.ucorp.io/ajax/marques_leads.php?json")
				.then(res => res.json())
				.then(
					(result) => { 
						var a = Object.keys(result).map((key) => [
							result[key].contact.split('|'),
						  ]);
						  const b= a.map((el:any, id:number) => {
							 return {id:id ,titre: el[0][0], numero: el[0][1], type: el[0][2] ?el[0][2] : ''  } 
						  } )
						  //console.log(b);
						  setitems(b) },
					(error) => { seterror(error) }
				)
		}

		getMarquesLeads();

	}, []);


	return (
	<>
		{error ? <div>Erreur : {error.message}</div> : isLoaded ? <div>Chargement…</div> :
		<DataGrid
		rows={items}
		columns={columns}
		pageSize={20}
		checkboxSelection
		disableSelectionOnClick
	/>
			 
			
		}
		
	</>
	)
}
export default TablesMarquesLeads;



