import React, { useEffect, useState,CSSProperties } from "react";
import { ICall } from "./ICall";

// return a simple component with a button
const CallDetailsFromA2Billing = (props:any) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch("https://ats-crmv2-business.ucorp.io/api/last-call-of/"+props.did);
    const data = await response.json();
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {  loading ? (<p>Loading / Loading / Loading </p>) : data.map((item:ICall) => (<p style={{backgroundColor: item.color}} key={item.id}>{item.ago} jours - {item.formatted_sessiontime} - {item.dnid}</p>)) }
    </div>
  );
}

export default CallDetailsFromA2Billing;
