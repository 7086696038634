import "./modal.css";
import { useEffect, useState } from "react";

const ModalComponent = (props: any) => {
  const [titre] = useState(
    props.titre === "Did" ? "Edition Catégorie d'un Did" : "Edition Catégories"
  );
  const [categoriesList, setCategoriesList] = useState<any>(
    props.categories.map((el: any) => {
      return { label: el.name, checked: false, idCategory: el["@id"] };
    })
  );
  useEffect(() => {
    setCategoriesList(props.categories.map((el:any) => {
      return { label: el.name, checked: false, idCategory: el["@id"] }
    }))
  }, [props.show])
  const showHideClassName = props.show
    ? "modal display-block"
    : "modal display-none";
  const toggle = (index: any) => {
    categoriesList.splice(index, 1, {
      label: categoriesList[index].label,
      checked: !categoriesList[index].checked,
      idCategory: categoriesList[index].idCategory,
    });

    setCategoriesList(categoriesList);
  };

  const onChangeCheck = () => {
    categoriesList.filter((x: any) => x.checked);
  };
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {props.children}
        <button type="button" onClick={props.handleClose}>
          X
        </button>
        <h3>{titre}</h3>
        {props.titre === "Did" ? (
          <form className={" row"} noValidate autoComplete="off">
            <p>{props.data ? props.data.did : "Titre"}</p>
            {categoriesList.map((item: any, index: any) => (
              <label className="col-md-2" key={item.label + index}>
                <input
                  readOnly
                  type="checkbox"
                  defaultChecked={Boolean(item.checked)}
                  onClick={() => toggle(index)}
                  onChange={() => onChangeCheck()}
                />
                <span
                  className="text-center"
                  style={{ fontSize: "16px", color: "black" }}
                >
                  {" "}
                  {item.label}
                </span>
              </label>
            ))}
            <button
              type="button"
              className="btn-grad col-md-2"
              onClick={() =>
                props.handleUpdateDid(categoriesList, props.data, props.titre)
              }
            >
              Valider
            </button>
          </form>
        ) : (
          <form className={" row"} noValidate autoComplete="off">
            <div>
              {showHideClassName === "modal display-block" ? (
                <div>
                  <input
                    autoFocus
                    type="text"
                    defaultValue={props.data.name || ""}
                    onChange={(e) => props.handleChange(e)}
                  />
                </div>
              ) : null}

              <h4>Sous catégories</h4>
              {categoriesList.map((item: any, index: any) => (
                <label className="col-md-2" key={item.label + index}>
                  <input
                    readOnly
                    type="checkbox"
                    defaultChecked={Boolean(item.checked)}
                    onClick={() => toggle(index)}
                    onChange={() => onChangeCheck()}
                  />
                  <span
                    className="text-center"
                    style={{ fontSize: "16px", color: "black" }}
                  >
                    {" "}
                    {item.label}
                  </span>
                </label>
              ))}
            </div>

            <button
              type="button"
              className="btn-grad col-md-2"
              onClick={() =>
                props.handleUpdateDid(categoriesList, props.data, props.titre)
              }
            >
              Valider
            </button>
          </form>
        )}
      </section>
    </div>
  );
};
export default ModalComponent;
