import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { getMounthName } from '../../Tools/utility';
import axios from 'axios';
import Loader from './Loader';
import moment from 'moment';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';

export default function Month({date1,setDate1, date2, setDids, did,setIsGlobal, setPseudoD1, setPseudoD2}:any) {
	const [datas, setDatas] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<Boolean>(true);

	const fetchDataTable = async () => {
        setIsLoading(true)
        const url = /^[^0-9]*$/.test(did) ?
            `https://ntx-remanence-web.dt-srv-195.ucorp.io/global/remanence/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}`:
            `https://ntx-remanence-web.dt-srv-195.ucorp.io/global/remanence/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}/${did}`
        const response = await axios.get(url);
		let data = await response.data;
		setDatas(data);
		let s: any = [];
		for (let i = 0; i < data.length; i++) s = new Set ([...s, ...data[i].dids.split(", ")]);
		setDids(s);
        setIsLoading(false);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {fetchDataTable();},[date2, date1, did]);


    return <>
        <section className="card-body col-md-10">
            <TableContainer style={{ height: 'calc(100vh - 50px)', overflowY: 'auto'}}>
                <Table>
                    <TableHead style={{ position: 'sticky', top: 0,zIndex: 100, backgroundColor: `rgba(255, 255, 255, 1)` }}>
                        <TableCell>PERIODE</TableCell>
                        <TableCell>REMANENCE</TableCell>
                        <TableCell>NOMBRE D'APPELANTS UNIQUES</TableCell>
                        <TableCell>NOMBRE D'APPELS</TableCell>
                    </TableHead>
                    <TableBody>
                        {
                            datas?datas.map((d: any, index: number) => (
                                <TableRow>
                                    <TableCell className='text-center'>
                                        <button 
                                            style={{backgroundColor:"transparent", border:"none", cursor:"pointer", textDecoration:"underline"}}
                                            onClick={() => {
                                                setIsGlobal(false); 
                                                setPseudoD1(startOfMonth(new Date(d.mois + "-01"))); 
                                                setPseudoD2(endOfMonth(new Date(d.mois+"-01")));
                                            }}
                                        >{getMounthName(parseInt(d.mois.split('-')[1], 10) - 1) + " "+ d.mois.split('-')[0]}</button></TableCell>
                                    <TableCell className='text-center'>{(parseFloat(d.calls)/parseFloat(d.caller_number)).toFixed(2)}</TableCell>
                                    <TableCell className='text-center'>{d.caller_number}</TableCell>
                                    <TableCell className='text-center'>{d.calls}</TableCell>
                                </TableRow>
                            )):<Loader/>   
                        }
                    </TableBody>
                </Table>
                {isLoading?<Loader/>:null}
                <button className='btn btn-primary col-md-12' onClick={() => {setDate1(subMonths(date1, 3));setIsLoading(true)}
                }>Plus de donnees</button>
            </TableContainer>
        </section>
        
    </>
}