import {FC, ReactElement } from "react";
import React from "react";
import { optionsPlanification } from "../../Tools/app";
import { Line } from "react-chartjs-2";
const PlanificationResult : FC<{data: object}> = ({ data}) : ReactElement => {
    return  <>
    				<div className="row">
					<div className="container col-md-10">
						<div>
							<Line type="line" data={data} options={optionsPlanification} />
						</div>
					</div>

				</div>
    </>
}
export default React.memo(PlanificationResult);