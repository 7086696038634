import React, { useContext, useState } from "react";
import Loading from "../spinner/spinner";
import jwt_decode from "jwt-decode";
import { convertMountNameDate } from "../librairies/dateFunction";
import ACDResultAffluence from "./Results/ACDResultAffluence";
import CheckboxComponent from "../librairies/checkbox";
import { TokenContext } from "../../App";

const Affluence = () => {
  const token = useContext(TokenContext);
  const userInfo: any = jwt_decode(useContext(TokenContext));
  const [page, setPage] = useState<number>(1);
  const [error, setError] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<Boolean>(false);
  const [did, setDid] = useState<string>("");
  const [didSend, setdidSend] = useState<any>();
  const [userDataDid] = useState<Array<any>>(
    userInfo.user_configurations.ALLOWED_DID.map((el: any) => {
      return { label: el, checked: false };
    })
  );
  const [numbersArray, setNumbersArray] = useState<Array<any>>(userDataDid);
  const [items, setItems] = useState<any>();
  const [groupeByDetail, setgroupeByDetail] = useState<string>("asc");
  const [selectOrder, setselectOrder] = useState<string>("");
  const [tableTitle] = useState<Array<string>>([
    "Appelant",
    "DID",
    "Premier appel sur le DID",
    "Nombre d'appels sur le DID",
    "TMC sur le DID",
  ]);

  const toggle = (index: any) => {
    numbersArray.splice(index, 1, {
      label: numbersArray[index].label,
      checked: !numbersArray[index].checked,
    });

    const response = numbersArray
      .filter((x) => x.checked)
      .map((el: any) => {
        return el.label;
      });
    setdidSend(response);
    setNumbersArray(numbersArray);
    setDid(
      JSON.stringify(response)
        .replaceAll(/,/gi, "-")
        .replaceAll(/"/gi, "")
        .slice(1, -1)
    );
  };

  const onChangeCheck = () => {
    numbersArray.filter((x) => x.checked);
  };
  const toCSV = () => {
    let data1 = [];
    const csvData = [tableTitle];
    for (let i = 0; i < items.length; i++) {
      data1.push([
        items[i].caller,
        items[i].called,
        convertMountNameDate(items[i].date_first_call),
        items[i].count,
        items[i].tmc,
      ]);
    }

    csvData.push(...data1);
    return csvData;
  };
  const handleDid = async (page: number) => {
    const data = {
      query: {
        did: didSend,
      },
      attributes: {
        pagination: { page },
      },
      query_affluence: true
      
    };
    did ? getData(data) : setItems([]);
  };

  const getData = (data: any) => {
    setIsLoaded(true);
    const dataPost: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(process.env.REACT_APP_API_URL + "/api/affluence/stats", dataPost)
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result.metadata);
          setIsLoaded(false);
          setError(null);
        },
        (err) => {
          setIsLoaded(false);
          setError(err);
        }
      );
  };
  const groupBy = (el: string, detail: string) => {
    detail === "asc" ? setgroupeByDetail("desc") : setgroupeByDetail("asc");
    setselectOrder(el);
    if (el === "Appelant") {
      const data = {
        query: {
          did: didSend,
        },
        attributes: {
          pagination: { page },
          order: { caller: groupeByDetail },
        },
      };
      getData(data);
    } else if (el === "DID") {
      const data = {
        query: {
          did: didSend,
        },
        attributes: {
          pagination: { page },
          order: { called: groupeByDetail },
        },
      };
      getData(data);
    } else if (el === "Premier appel sur le DID") {
      const data = {
        query: {
          did: didSend,
        },
        attributes: {
          pagination: { page },
          order: { date_first_call: groupeByDetail },
        },
      };
      getData(data);
    } else if (el === "Nombre d'appels sur le DID") {
      const data = {
        query: {
          did: didSend,
        },
        attributes: {
          pagination: { page },
          order: { count: groupeByDetail },
        },
      };
      getData(data);
    } else if (el === "TMC sur le DID") {
      const data = {
        query: {
          did: didSend,
        },
        attributes: {
          pagination: { page },
          order: { tmc: groupeByDetail },
        },
      };
      getData(data);
    }
  };
  const prec = () => {
    setPage(page - 1);
    handleDid(page - 1);
  };
  const suiv = () => {
    setPage(page + 1);
    handleDid(page + 1);
  };

  return (
    <>
      <div className="container-fluid">
        <h3 className="text-center title">AFFLUENCE</h3>
        <div className="row">
          <div
            className="col-12 col-lg-12 col-xl-4 col-md-12"
            style={{ margin: "20px auto" }}
          >
            <div className="card acdAffuencecontent">
              <div className="card-body acdAffuence table">
                <h2 className="text-center">Numéro </h2>
                <CheckboxComponent
                  items={numbersArray}
                  className={"col-md-4"}
                  toggle={toggle}
                  onChangeCheck={onChangeCheck}
                />
              </div>
              {did && !isLoaded ? (
                <button className="btn-grad" onClick={() => handleDid(page)}>
                  Afficher
                </button>
              ) : null}
            </div>
          </div>
          <div className="col-12  col-lg-12 col-xl-8 col-md-12">
            {error !== null ? (
              <h2 className="alert alert-danger" role="alert">
                Une Erreur est survenue
              </h2>
            ) : isLoaded ? (
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card ">
                        <div className="card-body ">
                          <Loading />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : items ? (
              <ACDResultAffluence
                data={items}
                title={tableTitle}
                page={page}
                did={did}
                prec={prec}
                suiv={suiv}
                selectOrder={selectOrder}
                groupeByDetail={groupeByDetail}
                toCSV={toCSV}
                groupBy={groupBy}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Affluence;
