import { FC, ReactElement } from "react";
import Table from "react-bootstrap/Table";
const AnalyseLogsTab: FC<{ items: any; serverName: string; title: string }> = ({
  items,
  serverName,
  title
}): ReactElement => {
  return (
    <div className="card">
      <div className="card-body ">
        <h2 className="text-center">Résultat</h2>
        {items.length > 0 ? (
          <div className={items.length > 30 ? "tableConfluence" : ""}>
            <Table striped bordered hover responsive>
              <tbody className="text-center">
                {items.map((el: any, index: any) => {
                  return (
                    <tr key={index + "k"}>
                      <td>
                        {" "}
                        <span
                          className={`etatAnalyse text-center  ${
                            title === "Légitimes"
                              ? "legitimeStatus"
                              : "suspectStatus"
                          }`}
                        >
                          {title === "Légitimes" ? "L" : "S"}
                        </span>
                      </td>
                      <td>{serverName.toUpperCase()}</td>
                      <td className="dateTimeClasse">{el}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AnalyseLogsTab;
