import {FC, ReactElement} from "react";
import Table from 'react-bootstrap/Table';
import { CSVLink } from 'react-csv';
import {convertMountNameDate} from "../../librairies/dateFunction";
type dataItem = {
    called: number,
    caller: string|null,
    count : number,
    date_first_call: string|null,
    tmc: string|null
}
const ACDAffluenceResult : FC<{items: dataItem[], titles:Array<string>, page:number, did:string, toCSV:any, prec:any, suiv:any, selectOrder:string, groupeByDetail:string, groupBy:any}> = ({items, titles, page, did, toCSV, prec, suiv, selectOrder, groupeByDetail, groupBy}) : ReactElement => {
    return   (
        (
            <div className="card">
                <div className="card-body ">
                    <h2 className="text-center">Résultat</h2>
                    {items.length > 0 ? (
                        <div className={items.length > 30 ? "tableConfluence" : ''}>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>{titles.map((el: string) => <th key={el} >{el} <span> <div style={{ float: "right" }}><i style={{ cursor: "pointer" }} className={selectOrder === el && groupeByDetail === "desc" ? 'nc-icon nc-stre-up' : 'nc-icon nc-stre-down'} onClick={() => groupBy(el, groupeByDetail)}></i></div></span> </th>)}</tr>
                                </thead>
                                <tbody className="text-center">
                                    {items.map((el: any, index: any) => {
                                        return (
                                            <tr key={index + "k"}>
                                                <td>{el.caller}</td>
                                                <td>{el.called}</td>
                                                <td>{convertMountNameDate(el.date_first_call)}</td>
                                                <td>{el.count}</td>
                                                <td>{el.tmc}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div className="t-end">
                                <span className="greater"> {page > 1 ? <i className="nc-icon nc-stre-left" onClick={() => prec()}></i> : null} </span>  {page} <span className="greater"> <i className="nc-icon nc-stre-right" onClick={() => suiv()}></i> </span>
                            </div>
                            <button className="btn-grad d-flex">
                                <CSVLink data={toCSV()} filename={`${did} - ${page}.csv`} >
                                    <i className="nc-icon nc-cloud-download-93" style={{ fontSize: "35px", marginRight: "10px" }}></i>
                                    <span style={{ marginTop: "5px" }}> Export CSV</span>
                                </CSVLink>

                            </button>
                        </div>
                    ) : (
                        items.length === 0 && page > 1 ? <div>
                            <h2 className="text-center">Aucun Résultat</h2>
                            <div className="t-end">
                                <span className="greater"><i className="nc-icon nc-stre-left" onClick={() => prec()}></i> </span>  {page} </div>
                        </div> : <h2 className="text-center">Aucun Résultat</h2>
                    )}

                </div>
            </div>
        )
    )
}

export default ACDAffluenceResult;