import {
    FETCH_PLANIFICATION_FAILURE,
    FETCH_PLANIFICATION_REQUEST,
    FETCH_PLANIFICATION_SUCCESS,
  } from "./planificationType";
  
  export const fetchDataPlanificationRequest = () => {
    return {
      type: FETCH_PLANIFICATION_REQUEST,
    };
  };
  
  export const fetchDataPlanificationSuccess = (PLANIFICATION: any) => ({
    type: FETCH_PLANIFICATION_SUCCESS,
    payload: PLANIFICATION,
  });
  export const fetchDataPlanificationFailure = (error: string) => ({
    type: FETCH_PLANIFICATION_FAILURE,
    payload: error,
  });
  
  export const PostDataPlanification = (startDate: string, endDate: string) => {
    return async (dispatch: any) => {
      dispatch(fetchDataPlanificationRequest());
      //@ts-ignore
      const urlData = `${
        process.env.REACT_APP_API_URL
      }/api/planification?startDate=${startDate} 00:00:00&endDate=${endDate} 23:59:59`;
      fetch(urlData)
        .then((res) => {
          if (!res.ok) {
          }
          return res.json();
        })
        .then(
          (result) => {
            dispatch(fetchDataPlanificationSuccess(result));
          },
          (err) => {
            dispatch(fetchDataPlanificationFailure(err));
          }
        );
    };
  };
  