
import { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
type IsToShow = {
    Answered:boolean;    
    "Call Failed":boolean;    
    Cancelled:boolean;    
    "Pool expired":boolean;    
    "User requested":boolean;    
    "Caller has dropped call":boolean;    
    "Transfer OK":boolean; 
}
const CallAnalysisGraph = ({data}:any)=>{
    const [isToShow, setIsToShow]=useState<IsToShow>({
        Answered:true,
        "Call Failed":true,
        Cancelled:true,
        "Pool expired":true,
        "User requested":true,
        "Caller has dropped call":true,
        "Transfer OK":true
    });
    const handleToggleAttribute = (data:keyof IsToShow) => {
        let tempState = isToShow;
        console.log(tempState)
        setIsToShow(prevState=>({
            ...prevState,
            [data]:!prevState[data]
        }))
    };
    
    return <>
        <LineChart width={1000} height={300} data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend onClick={e=>handleToggleAttribute(e.dataKey)} verticalAlign="top" height={36}/>
            <Line type="linear" dataKey="Answered" stroke="#8884d8" hide={!isToShow["Answered"]}/>
            <Line type="linear" dataKey="Call Failed" stroke="#82ca9d" hide={!isToShow["Call Failed"]}/>
            <Line type="linear" dataKey="Cancelled" stroke="#261d08" hide={!isToShow["Cancelled"]}/>
            <Line type="linear" dataKey="Pool expired" stroke="#fcba03" hide={!isToShow["Pool expired"]}/>
            <Line type="linear" dataKey="User requested" stroke="#008394" hide={!isToShow["User requested"]}/>
            <Line type="linear" dataKey="Caller has dropped call" stroke="#0544e6" hide={!isToShow["Caller has dropped call"]}/>
            <Line type="linear" dataKey="Transfer OK" stroke="#ff0000" hide={!isToShow["Transfer OK"]}/>
        </LineChart>
    </>
}


export default CallAnalysisGraph;