import { FC, ReactElement, useEffect, useRef, useState } from "react";
const Analyse: FC<{ datas: object }> = ({ datas }): ReactElement => {
  const [items] = useState<string>(datas.toString());
  const spanRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = items;
    }
  }, [items]);
  return (
    <div className="card">
      <div className="card-body">
        <h2 className="text-center">Résultat</h2>
        {items ? (
          <span ref={spanRef} />
        ) : (
          <h2 className="text-center">Aucun Résultat</h2>
        )}
      </div>
    </div>
  );
};

export default Analyse;
