import { useState } from 'react';
import { endOfMonth, setDate, startOfMonth, subMonths } from 'date-fns';
import Mounth from './Mounth';
import Daily from './Daily';


export default function PersistentDrawerRight() {
	const [date1, setDate1] = useState<any>(startOfMonth(subMonths(new Date(),5)));
	const [date2, setDate2] = useState<any>(endOfMonth(new Date()));
	const [dids, setDids] = useState<any>([]);
	const [selectedButton, setSelectedButton] = useState<any>([]);
	const [isGlobal, setIsGlobal] = useState<boolean>(true);
	const [pseudoD1, setPseudoD1] = useState<any>(null);
	const [pseudoD2, setPseudoD2] = useState<any>(null);
	const [titre, setTitre] = useState<string>("Global");
	const [did, setDid] = useState<string>("");

	return (
		<>
			<div className="container-fluid blck-comparatif">
				<h3 className="text-center title">RÉCURRENCE {titre}</h3>
			</div>
			<div className='card'>
				<section className="card-body">
					<p className='row text-center'>
						<button
							onClick={()=>{setIsGlobal(true);setTitre("Global")}}
							style={{marginLeft:10, float:'right', justifyContent:'space-between'}} 
							className='btn btn-primary col-md-12'>Mois</button>
					</p>
				</section>
			</div>
			<div className='card' style={{  position: '-webkit-sticky', top: 0, zIndex: 100 }}>
				<div className='row' style={{position: 'sticky', bottom: 0, backgroundColor: `rgba(255, 255, 255, 1)`}}>
					<section className='card-body col-md-2' style={{ height: 'calc(100vh - 50px)', overflowY: 'auto'}}>
					<h3 className='text-center' style={{ position: 'sticky', top: 0,zIndex: 100, backgroundColor: `rgba(255, 255, 255, 1)` }}>Dating</h3>
						<button className="btn-grad col-md-12"onClick={()=>{
							setSelectedButton(1000);
							setDid("");
						}}
						style={{backgroundColor: selectedButton === 1000 ? 'black' : ''}}
						>TOUT</button>
						{
							dids ? Array.from(dids).map((item: any, index: number) => (
								<button key={index} className="btn-grad col-md-12" 
								onClick={()=>{
										setSelectedButton(item);
										setTitre(item);
										setDid(item);
									}}
								style={{backgroundColor: item === selectedButton ? 'black' : ''}}
								>{item}</button>
							)):null
						}
					</section>					
					{
						isGlobal?<Mounth
							date1={date1}
							setDate1={setDate1}
							date2={date2}
							setDids={setDids}
							setIsGlobal={setIsGlobal}
							setPseudoD1={setPseudoD1}
							setPseudoD2={setPseudoD2}
							did={did}
						/>:
						<Daily
							date1={pseudoD1}
							date2={pseudoD2}
							did={did}
							setDids={setDids}
						/>
					}
				</div>
			</div>
		</>
	);
}