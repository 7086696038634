import React, { FC, useContext, useEffect, useState } from "react";
import Loading from "../spinner/spinner";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { PostDataHardware } from "../../store";
import { TokenContext } from "../../App";

const Hardware : FC<{ data: any; PostDataHardware: any }> = ({
  data,
  PostDataHardware,
}) => {
  const token = useContext(TokenContext);
  console.log(data)
  useEffect(() => {
    const getData = () => {
      const dataHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      PostDataHardware("/api/customer/data", dataHeader)
    }
    getData();
  }, []);
  return (
    <>
      <div className="container-fluid blck-comparatif">
        <h3 className="text-center title">Hardware</h3>
        <div className="row">
          {data.error !== null ? (
            <h2 className="alert alert-danger" role="alert">
              Une Erreur est survenue
            </h2>
          ) : data.isLoaded ? (
            <div className="content" style={{ margin: "20px auto" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-body ">
                        <Loading />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : data.nagios ? (
            <div className="card" style={{ margin: "20px auto" }}>
              <div className="card-body max-width-mdcent">
                {data.nagios.length > 0 ? (
                  <div className="row">
                    {
                      <TableContainer component={Paper} className="mb-5">
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {data.nagios.map((item: any, ind: number) => {
                                return (
                                  <>
                                    <TableCell key={item + ind} className="text-center">
                                      {item[0]}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          {
                            <TableBody className="LogCurrentCall">
                              <TableRow>
                                {data.nagios.map((item: any, ind: number) => {
                                  return (
                                    <>
                                      <TableCell key={item[0] + ind}>
                                        {item[1].map((el:any, index:number) => {
                                          return <p className={`text-center m-0  ${(index % 2) === 0 ? "statusOK" : "statusKO"}`}  key={el.date_time + index}>{el.date_time} - {el.probed_data}</p>
                                        })}
                                      </TableCell>
                                    </>
                                  );
                                })}
                              </TableRow>
                            </TableBody>
                          }
                        </Table>
                      </TableContainer>
                    }
                    {
                      <Tabs>
                        <TabList>
                          {data.muning.map((item: any, ind: number) => {
                            return (
                              <>
                                <Tab key={item + ind +"muning"}>{item[0]}</Tab>
                              </>
                            );
                          })}
                        </TabList>
                        {data.muning.map((item: any, inde: number) => {
                          return (
                            <>
                              <TabPanel
                                className="row"
                                key={inde + item[0] + inde + "sss"}
                              >
                                {item[1].map((el: any, index: number) => {
                                  return (
                                    <>
                                      <div
                                        className="col-md-6"
                                        key={"hard" + el}
                                      >
                                        <img src={el} alt={item + index} />
                                      </div>
                                    </>
                                  );
                                })}
                              </TabPanel>
                            </>
                          );
                        })}
                      </Tabs>
                    }
                  </div>
                ) : (
                  <h2 className="text-center">Aucun Résultat</h2>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    data: state.hardware,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    PostDataHardware: (url: string, dataToPost: any) =>
      dispatch(PostDataHardware(url, dataToPost)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Hardware);