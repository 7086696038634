import React, {  useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
const RequalificationForm = (props: any) => {
  const [choices] = useState<Array<string>>([
    "",
    "Objet perdus - trouvé",
    "Mairie",
    "Serrurier",
    "Bureau de Poste",
    "Pharmacie",
    "ENCOMBRANTS",
    "INTERDIT BANCAIRE",
    "Banques",
    "Opposition CB",
    "Compagnies aériennes - agences de voyage",
    "Pannes et coupure (EDF, Enedis, Eau, Internet, Box, etc.)",
    "CORONAVIRUS",
    "FOURRIERES",
    "MUTUELLE",
    "OPERATEUR",
    "Sites e-commerce",
    "TRANSPORTEUR",
    "ASSURANCE",
    "Hotel",
    "Grande distribution, Fast food, Taxi nationaux, Franchises, magasins,..",
    "ANNUAIRE INVERSÉ",
    "ANNUAIRE PARTICULIER",
    "CANAL+/CANALSAT",
    "JOURNAUX (la tribune,le républicain,...)",
    "ALLO MAIRIE",
    "AUTRE",
    "Chien/Chat perdus - trouvé",
    "Suivi postal (Colis, lettres, etc.)",
    "Agence Immobilieres",
    "Opérateur Mobile",
  ]);
  const [error, setError] = useState<any>(null);

  const handleChoice = (e: any) => {
    fetch(process.env.REACT_APP_API_URL + "/api/call/qualification", {
      method: "POST",
      body: JSON.stringify({
        qualification: e.target.value,
        uniqueid: props.callid,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    }).then(
      (result) => {
        setError(null);
      },
      (err) => {
        setError(err);
      }
    );
    e.preventDefault();
  };
  return (
    <FormControl style={{ marginLeft: "5%" }}>
      <Select onChange={handleChoice} defaultValue="">
        {choices.map((q: any, i: any) => {
          return (
            <MenuItem key={i} value={q}>
              {q}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default RequalificationForm;
