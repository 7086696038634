import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge, makeStyles, Theme } from '@material-ui/core';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
const tabStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
export default function NotificationMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classesTab = tabStyles();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const [notifications] = useState<Array<any>>([
        {
            type: "urgent",
            message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        {
            type: "important",
            message: " Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            type: "informatif",
            message: " Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate"
        }
    ])
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                variant="contained"
                onClick={handleClick}
            >
                <Badge overlap="rectangular" badgeContent={4} max={99} color="primary">
                    <NotificationsIcon />
                </Badge>
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                <div className={classesTab.root}>

                    <div className="scrollbar-container NotifCate">
                        <div className="MuiGrid-header">
                            <div className="jss152">
                                <div className="MuiGrid-root MuiGrid-container">
                                    <div className="MuiGrid-root MuiGrid-item css-13i4rnv-MuiGrid-root">
                                        <div className="css-1ysch1t-Stack-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle1">Notification</h6>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                       
                        <hr className="MuiDivider"/>
                        <div className="MuiGrid-container">
                            {
                                notifications.map((el:any, index: number) => {
                                    return  <div className="MuiGrid-jss275" key={index + "notif"}>
                                    <ul className="MuiList-padding">
                                        <div className="jss275" style={{ cursor: "pointer"}}>
                                            <li className="MuiListItem-container">
                                                <div className="MuiListItemAvatar-root"><div className={"MuiAvatar-circular " + el.type} ></div></div>
                                                <div className="MuiListItem-gutters">
                                                    <div className="MuiListItemText">
                                                        <h6 className="MuiTypography-subtitle1">John Doe</h6>
                                                    </div>
                                                </div>
                                                <div className="MuiListItemSecondaryAction-root">
                                                    <div className="MuiGrid-container">
                                                        <div className=" MuiGrid-item">
                                                            <span className="MuiTypography-caption">2 min ago</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <div className=" MuiGrid-container" style={{ paddingLeft: "56px" }}>
                                                <div className=" MuiGrid-containt">
                                                    <h6 className=" MuiTypography-subtitle2">{el.message}</h6>
                                                </div>
                                                <div className=" MuiGrid-btn">
                                                    <div className=" MuiGrid-container">
{/*                                                         <div className=" MuiGrid-item">
                                                            <div className="MuiChip-filled">
                                                                <span className="MuiChip-label">Unread</span>
                                                            </div>
                                                        </div> */}
                                                        <div className=" MuiGrid-item">
                                                            <div className="MuiChip-filled">
                                                                <span className={el.type} >{el.type}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                    <hr />
                                </div>
                                
                                })
                            }
                           
                        </div>
                        <hr className="MuiDivider"/>
                        <div className="MuiCardActions-root text-center"><button className="MuiButton-text" type="button">View All<span className="MuiTouchRipple-root"></span></button></div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
}
