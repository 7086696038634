export const convertMinToSec = (data: number) => {
  return data * 60;
};
export const getSelectDate = (dateDebut: string, dateFin: string) => {
  if (
    hourlyDiff(new Date(convert(dateDebut)), new Date(convert(dateFin))) < 24
  ) {
    return "hourly";
  } else if (
    24 <=
      hourlyDiff(new Date(convert(dateDebut)), new Date(convert(dateFin))) &&
    monthDiff(new Date(convert(dateDebut)), new Date(convert(dateFin))) === 0
  ) {
    return "daily";
  } else if (
    monthDiff(new Date(convert(dateDebut)), new Date(convert(dateFin))) <= 12
  ) {
    return "monthly";
  } else if (
    12 < monthDiff(new Date(convert(dateDebut)), new Date(convert(dateFin)))
  ) {
    return "yearly";
  }
};
export const moveDay = (day: number) => {
  var today = new Date();
  // eslint-disable-next-line no-eval
  var prevWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    eval(`${today.getDate() + day}`)
  );
  return prevWeek;
};

export const hourlyDiff = (d1: Date, d2: Date) => {
  return (d2.getTime() - d1.getTime()) / (590 * 3600);
};
export const dayDiff = (d1: Date, d2: Date) => {
  return (d2.getTime() - d1.getTime()) / (590 * 3600 * 24) + 1;
};
export const monthDiff = (d1: Date, d2: Date) => {
  return (
    d2.getMonth() - d1.getMonth() + 12 * (d2.getFullYear() - d1.getFullYear())
  );
};
export const convert = (str: string) => {
  var mnths: any = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const date = str.toString().split(" ");
  return [date[3], mnths[date[1]], date[2]].join("-");
};
export const convertSecToH = (secs: any) => {
  if (secs) {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return (
      [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":") +
      " (" +
      (secs / 3600).toFixed(2) +
      "h)"
    );
  }
  return "00:00:00";
};

export const convertMountNameDate = (dates: string) => {
  var mnths: any = {
    "01": "Janv.",
    "02": "Fevr.",
    "03": "Mars",
    "04": "Avr.",
    "05": "Mai",
    "06": "Juin",
    "07": "Juil.",
    "08": "Août",
    "09": "Sept.",
    "10": "Oct.",
    "11": "Nov.",
    "12": "Dec.",
  };
  const date = dates.replaceAll(/-/gi, " ").split(" ");
  return [date[2], mnths[date[1]], date[0]].join(" ");
};
export const convertArrayToString = (data: string | string[]) => {
  return JSON.stringify(data)
    .replaceAll(/,/gi, "-")
    .replaceAll(/"/gi, "")
    .slice(1, -1);
};
export const convertSec = (secs: any) => {
  if (secs) {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return (
      [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":") +
      " (" +
      (secs / 3600).toFixed(2) +
      "h)"
    );
  }
  return "00:00:00";
};
export const optimalChange = (change: number) => {
  let rest10 = change % 10;
  let rest5 = null;
  let rest2 = null;
  let coin10 = 0;
  let coin5 = 0;
  let coin2 = 0;
  if (change / 10 >= 1) {
    coin10 = (change - rest10) / 10;
    change = rest10;
  }
  if (change / 5 >= 1) {
    rest5 = change % 5;
    coin5 = (change - rest5) / 5;
    change = rest5;
  }
  rest2 = change % 2;
  if (rest2 < 1) {
    coin2 = change / 2;
  } else {
    return {
        coin10,
        coin5,
        coin2 : null,
      };;
  }
  return {
    coin10,
    coin5,
    coin2,
  };
};

export const solveColis = (
  width: number,
  height: number,
  length: number,
  mass: number
) => {
  let encombrant =
    width * height * length >= 1000000 ||
    width >= 105 ||
    height >= 150 ||
    length >= 150;
  let lourd = mass >= 20;
  console.log(encombrant ? "eny": "tsia", mass )
  if (encombrant === false && lourd === false) {
    return "STANDARD";
  }  if (encombrant  && lourd) {
    return "REJECTED";
  }
  if (encombrant || lourd) {
    return "SPECIAL";
  }

  return null;
};

export const closestToZero = (numbers: Array<number>) => {
    if (numbers === null || numbers.length === 0) {
        return 0
    }
    var closest = numbers.reduce(function(prev, curr) {
        return (Math.abs(curr) < Math.abs(prev) ? curr : prev);
      });   
      if (numbers.includes(Math.abs(closest))) {
          return Math.abs(closest)
      } else {
        return closest
      }
}
export function factorial(n: number): any {
    if (n === 0) {
        return 1;
    } else {
        return n * factorial(n - 1);
    }
}
export const sameNumber = (i:number, j:number) => {
    return (i === 1 || j === 1 || (i+j) === 1 )
}

export function findLargest(numbers:any) {
var largest = numbers[0];

for (var i = 0; i < numbers.length; i++) {
  if (largest < numbers[i] ) {
      largest = numbers[i];
  }
}
return largest
}
export function lineBreak(char: string) {

  return char.replace(/ /g, "\n")
}

export function reshape(n:number, str: string) {
  str = str.replace(/\s/g, '');
  let res = "";
  for (var i = 0; i < str.length; i++) {
      if (i % n === 0 && i !== 0)
          res = res + '\n' + str.charAt(i);
      else
          res += str.charAt(i);
  }

  return res;
}
export function countPaireNombre(n: number) {
  let results = 0;
  for (let i = 0; i < n - 1; i++) {
    for (let j = i + 1; j < n; j++) {
        results++;
    }
  }


  return results;
}
export function approxPi(pts: any) {
  let arrayPi = [];
  let valuePi;
  for(let i = 0; i < pts.length;i++){
      let point = pts[i];
      let x = point[0];
      let y = point[1];

      if(Math.pow(x, 2) + Math.pow(y, 2) <= 1){
        arrayPi.push(point);
      }
  }

  valuePi = 4 * (arrayPi.length / pts.length);

  return valuePi;
}
export function reverseBySeparator( string: string, separator: string) {
  return string.split(separator).reverse().join(separator);
}
export function isAnagram(first: string, second: string ) {
  // For case insensitivity, change both words to lowercase.
  var a = first.toLowerCase();
  var b = second.toLowerCase();

  // Sort the strings, and join the resulting array to a string. Compare the results
  a = a.split("").sort().join("");
  b = b.split("").sort().join("");

  return a === b;
}
export function calc(array: any[], i1: any, i2: number){
  let result = 0;

  array.slice(i1, (i2 + 1)).forEach(element => {
      result += element;
  });

  return result;
}
