const setLocalStorage = (key: string, value: any, expirationDays: number = 7): void => {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = date.toISOString();
    const item = { value, expires };
    localStorage.setItem(key, JSON.stringify(item));
}

const getLocalStorage = (key: string): any => {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
        return '';
    }
    const item = JSON.parse(itemString);
    const now = new Date();
    const expirationDate = new Date(item.expires);

    if (now > expirationDate) {
        // La valeur a expiré, la supprimer du stockage local
        localStorage.removeItem(key);
        return '';
    }
    return item.value;
}
const fetchOrCheck=(date:string):boolean=>{
    const dateToCompare = new Date(date);
    const currentDate = new Date();
    // Extraire la partie "date" (année, mois, jour) de chaque date
    const dateToCompareOnlyDate = new Date(dateToCompare.getFullYear(), dateToCompare.getMonth(), dateToCompare.getDate());
    const currentDateOnlyDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    return dateToCompareOnlyDate.getTime() === currentDateOnlyDate.getTime()? true:false;
}
export { setLocalStorage, getLocalStorage, fetchOrCheck };

export const getMounthName = (mounth: number): string => {
    const mounthName = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
      ];
    return mounthName[mounth];
}

export const generateUniqueKey = () => {
    return `${Math.random().toString(36).substr(2, 9)}-${Date.now().toString(36)}`;
  };

  // Fonction pour convertir une durée (HH:MM:SS) en secondes
export const durationToSeconds = (duration: string): number => {
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
// Fonction pour convertir des secondes en durée (HH:MM:SS)
export const secondsToDuration = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const finalSeconds = Math.round(seconds % 60).toString().padStart(2, '0');

    
    return `${hours}:${minutes}:${finalSeconds}`;
};

  