
import React, { Component } from 'react'
import "../../assets/css/app.css";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationMenu from '../notification/notification'
import { Toolbar, IconButton } from '@material-ui/core';

interface iProps {
  setToken: any
}
class Navbar extends Component<iProps> {
  logout = () => {
    localStorage.clear()
    this.props.setToken(null)
  }
  render() {
    return (
      <Toolbar className="navbar navbar-expand-lg " >
        <div className="container-fluid">
            <a className="navbar-brand" title="Accueil" style={{ cursor: "pointer", fontSize: "15px" }} href="/">
              <IconButton color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton></a>
            <div className="collapse navbar-collapse justify-content-end" id="navigation">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item notification-system">
                  <span style={{ cursor: "pointer" }} title="Notification">
                    <NotificationMenu />
                  </span>
                </li>
                <li className="nav-item logout-system">
                  <span style={{ cursor: "pointer" }} title="Déconnexion" className="no-icon" onClick={() => this.logout()}><ExitToAppIcon /></span>
                </li>
              </ul>
            </div>
          </div>
      </Toolbar>

    )
  }
}

export default Navbar
