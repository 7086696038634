import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { secondsToDuration } from '../../Tools/utility';

type GraphData = {
  day: string;
  numberOfCalls: number;
  totalDurationServices: number;
  totalDurationWaiting: number;
};

type GraphProps = {
  graphData: GraphData[];
};

const GraphVeto: React.FC<GraphProps> = ({ graphData }) => {
    const renderCustomTooltip = (props: any) => {
        const { active, payload } = props;
    
        if (active && payload && payload.length) {
          const data = payload[0].payload; // Get the data object
    
          return (
            <div style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px' }}>
              <p>{`${data.day}`}</p>
              <p style={{ color: '#8884d8' }}>{`numberOfCalls: ${data.numberOfCalls}`}</p>
              <p style={{ color: '#82ca9d' }}>{`totalDurationServices: ${secondsToDuration(parseFloat(data.totalDurationServices)*100)} `}</p>
              <p style={{ color: '#ff7300' }}>{`totalDurationWaiting: ${secondsToDuration(parseFloat(data.totalDurationWaiting)*100)} `}</p>
            </div>
          );
        }
    
        return null;
      };
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={graphData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip content={renderCustomTooltip} />
        <Legend />
        <Line type="monotone" dataKey="numberOfCalls" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="totalDurationServices" stroke="#82ca9d" />
        <Line type="monotone" dataKey="totalDurationWaiting" stroke="#ff7300" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GraphVeto;
