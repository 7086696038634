import io from 'socket.io-client';
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { ToastContainer, toast } from "react-toastify";

const Notify = () => {
  const [ag, setTest] = React.useState([])
  var title:string;
  var notif:string;
  var agent:any;
  var notifier:string;

  var AGENT:string[];
  function sendtosoket(){
    console.log({
            'notification_title': title,
            'notification': notif,
            'fk_ext': agent,
            'notifier': notifier
          });
    if (!notifier) notifier = "0";
        const socket = io('https://ats-crmv2-notification.atscom.io');
        socket.emit(
          'send_notifications',{
            'notification_title': title,
            'notification': notif,
            'fk_ext': agent,
            'notifier': notifier
          }
      );
  }
  function resetVar(){
      title = ''
      notif = ''
      agent = ''
      notifier = ''
  }
  function notificationSent(mess:string){
    toast(mess, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
  }
  function notificationnotsent(mess:string){
    toast.error(mess, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
  }
  function handleSubmit (event:any) {
    event.preventDefault();   
  
  if(title && notif ){
    if(!agent){
      agent=null
      sendtosoket()
      notificationSent('Tout le mondes est notifié')
      resetVar()
      event.target.reset();
    }
      
    for (let a in ag){
      if(ag[a]['ext'] + ('_')+ag[a]['name']===agent){      
        agent =ag[a]['ext']
          sendtosoket()
          notificationSent( agent + ' Notifié')
          resetVar()
          event.target.reset();
          break
        }        
      }
    }
    else notificationnotsent('Notification non envoyer. Veiller bien replir les entrés'); 
  }
  
  React.useEffect(() => {
    fetch('https://ats-crmv2-notification.atscom.io/agent/get')
      .then(res => res.json())
      .then((result) => {
        setTest(result)
      }, (err) => {
        console.log(err)
      })
  }, [])
  
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container-fluid blck-comparatif">    
        <form onSubmit={handleSubmit}>
          <h1 className="text-center title">Notifier agent</h1>
          <div className="card col-md-12">
            <div className="card-body">
              <div className='form-group'>
                <label htmlFor="inputTitle">Titre de la notification</label>
                <input className="col-md-12" type="text" placeholder='Objet Notification' id='title' onChange={(e)=> title = e.target.value}/>
              </div>
              <div className='form-group'>
                <label htmlFor="inputNotif">Notification</label>
                <textarea className="col-md-12" placeholder='Ajouter ici le corps de votre notification' id='notif' onChange={(e)=>notif = e.target.value}/>
              </div>
              <div className='form-row'>
                <label htmlFor="inputNotifier">AGENT</label>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(e,value)=>agent = value}
                    options={ag.map(item=>item['ext']+'_'+item['name'])}
                    renderInput={
                      (params) => <TextField onChange={(e)=>{agent = e.target.value}}  {...params} label="Agent" />
                    }
                />
                <div className='form-group col-md-12'>
                  <label htmlFor="inputNotifier">NOTIFIER</label>
                  <input type="number" className='form-control col-md-12 pb-2' placeholder="Votre ID..." id="inputNotifier"  onChange={(e)=>notifier = (e.target.value)}/>
                </div>
              </div>
            </div>
            <button className='col-md-12 btn btn-primary' type="submit" id='send'>Envoyer la notification</button>
          </div>
        </form>    
      </div>
    </>
  )
}

export default Notify;
