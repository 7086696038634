import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FC, ReactElement } from "react";

type dataCheckbox = {
  label: string;
  checked: boolean;
};
const CheckboxComponent: FC<{
  items: dataCheckbox[];
  className: string;
  toggle: any;
  onChangeCheck: any;
}> = ({ items, className, toggle, onChangeCheck }): ReactElement => {
  return (
    <div className="row w-100">
      {items.length > 0
        ? items.map((item: any, index: any) => (
            <FormControlLabel
              className={className}
              key={item.label + index}
              control={
                <Checkbox
                  onClick={() => toggle(index)}
                  onChange={() => onChangeCheck()}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  name={item.label}
                  checked={item.checked}
                />
              }
              label={item.label}
            />
          ))
        : null}
    </div>
  );
};
export default CheckboxComponent;
