import {
  FETCH_DATERANGE_FAILURE,
  FETCH_DATERANGE_REQUEST,
  FETCH_DATERANGE_SUCCESS,
  FETCH_DATANALYSE_SUCCESS,
  FETCH_DATAGENTRANKING_SUCCESS,
} from "./dateRangeType";

const initialState = {
  dateRangeData: null,
  dataAnalyse: null,
  dataAgentRanking: null,
  error: null,
  loading: false,
};
const dateRangeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_DATERANGE_REQUEST:
      return { ...state, loading: true };
    case FETCH_DATERANGE_SUCCESS:
      return {
        dateRangeData: action.payload,
        error: null,
        loading: false,
        dataAnalyse: [],
      };
    case FETCH_DATANALYSE_SUCCESS:
      return {
        dataAnalyse: action.payload,
        error: null,
        loading: false,
        dateRangeData: [],
        dataAgentRanking: []
      };
    case FETCH_DATAGENTRANKING_SUCCESS:
      return {
        dataAgentRanking: action.payload,
        dataAnalyse: [],
        error: null,
        loading: false,
        dateRangeData: [],
      };
    case FETCH_DATERANGE_FAILURE:
      return {
        dataAnalyse: [],
        dataAgentRanking: [],
        dateRangeData: [],
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default dateRangeReducer;
