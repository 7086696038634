/**
 * @author("tiana.randrianarivony@gmail.com")
 */

import {FC, ReactElement, useEffect, useState} from "react";
import { Bar } from 'react-chartjs-2'
import {ChartOptions, chartPatternData} from "../../../Tools/app";

const ACDChartResult : FC<{datas: object}> = ({ datas}) : ReactElement => {
    const [dataChart, setDataChart] = useState(chartPatternData)

    useEffect(() => {
        let x = dataChart;
        const labels = Object.keys(datas);
     
            const tmc : object[] = [];
            const duration : object[] = []
            const calls : object[] = []
            Object.values(Object.values(datas)).forEach((d, index) => {
                const xLabel = labels[index];
                tmc.push({x : xLabel, y: d.metadata.average_duration_int/3600})
                duration.push({x: xLabel, y : d.metadata.overall_duration_int/3600})
                calls.push({x: xLabel, y : d.metadata.call_number})
            })
            x.datasets[0].data = tmc
            x.datasets[1].data = duration
            x.datasets[2].data = calls
        setDataChart(x);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(datas), JSON.stringify(dataChart), dataChart, datas])
    return    <Bar type="line" data={dataChart} options={ChartOptions} />
}

export default ACDChartResult;