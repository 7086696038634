import { useContext, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import jwt_decode from "jwt-decode";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import ModalComponent from "../librairies/modalComponent";
import Loading from "../spinner/spinner";
import { TokenContext } from "../../App";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddCategories = (props: any) => {
  const token = useContext(TokenContext);
  const [didSend, setDidSend] = useState<any>();
  const [name, setName] = useState<string>();
  const [script, setScript] = useState<string>();
  const [error, setError] = useState<any>(null);
  const [isLoaded, setIsLoading] = useState<Boolean>(false);
  const [did, setDid] = useState<Array<any>>([]);
  const [categorieList, setCategorieList] = useState<Array<any>>([]);
  const [isSubMenu, setIsSubMenu] = useState<boolean>(false);
  const [isParent, setIsParent] = useState<boolean>(false);
  const [isDidCreate, setisDidCreate] = useState<boolean>(false);

  const [subCategory, setSubCategory] = useState<string>("");
  const [personName, setPersonName] = useState<string[]>([]);
  const [items, setItems] = useState<Array<any>>([]);
  const classes = useStyles();
  const theme = useTheme();
  const [category, setCategorie] = useState<string>("");
  const [categorieSend, setCategorieSend] = useState<Array<string>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dataSendModal, setDataSendModal] = useState<any>();
  const [categoriesList, setCategoriesList] = useState<Array<any>>([]);
  const [titreModal, setTitreModal] = useState<string>("");
  const [categoryNewName, setcategoryNewName] = useState<string>("");
  const [indexData, setIndexData] = useState<number>(0);
  const handleSubCategory = (event: any) => {
    setSubCategory(event.target.value["@id"]);
  };
  const handleCategory = (event: any) => {
    setCategorie(event.target.value["@id"]);
  };
  const onChangeCheck = () => {
    setIsSubMenu(!isSubMenu);
    if (isParent) {
      setIsParent(false);
      setCategorie(null as any);
    }
  };

  const onChangeCheckParent = () => {
    setIsParent(!isParent);
    if (isSubMenu) {
      setIsSubMenu(false);
      setSubCategory(null!);
    }
  };
  const onChangeCheckisDidCreate = () => {
    setisDidCreate(!isDidCreate);
  };
  const handleSubmit = async (e: any, url: string) => {
    e.preventDefault();
    let dataSend;
    if (url.includes("did")) {
      dataSend = {
        did: didSend,
        categories: isDidCreate ? null : [categorieSend[0]],
      };
    } else {
      dataSend = {
        name,
        script,
        subCategories: [subCategory],
        categories: [category],
      };
    }
    const dataPost: RequestInit = {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(process.env.REACT_APP_API_URL + url, dataPost)
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          setError("ERROR");
          return "ERROR";
        }
        return res.json();
      })
      .then(
        (result) => {
          const a = Object.keys(result).map((key) => [
            String(key),
            result[key],
          ]);
          setItems(a);
          setIsLoading(false);
          setError(null);
        },
        (err) => {
          setIsLoading(false);
          setError(err);
        }
      );
  };
  useEffect(() => {
    setIsLoading(true);
    const getAllsubCategories = () => {
      const config: RequestInit = {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      };
      fetch(process.env.REACT_APP_API_URL + "/api/categories/parents", config)
        .then((res) => res.json())
        .then(
          (result) => {
            setCategorieList(result["hydra:member"]);
            setError(null);
          },
          (err) => {
            setIsLoading(false);
            setError(err);
          }
        );
    };
    const getAllDid = () => {
      const config: RequestInit = {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      };
      fetch(process.env.REACT_APP_API_URL + "/api/did/listing", config)
        .then((res) => res.json())
        .then(
          (result) => {
            setDid(result["hydra:member"]);
            setError(null);
          },
          (err) => {
            setIsLoading(false);
            setError(err);
          }
        );
    };
    const getAllCategoriesList = () => {
      const config: RequestInit = {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      };
      fetch(process.env.REACT_APP_API_URL + "/api/categories/list", config)
        .then((res) => res.json())
        .then(
          (result) => {
            setCategoriesList(result["hydra:member"]);
            setIsLoading(false);
            setError(null);
          },
          (err) => {
            setIsLoading(false);
            setError(err);
          }
        );
    };
    getAllsubCategories();
    getAllDid();
    getAllCategoriesList();
  }, []);
  const handleChange = (event: any) => {
    setPersonName(event.target.value);
  };
  const getData = (name: any) => {
    let categories = categorieSend;
    const index = categorieSend.indexOf(name["@id"]);
    if (index > -1) {
      categories = categorieSend.splice(index, 1);
      setCategorieSend(categories);
    } else if (name) {
      let options = name["@id"];
      setCategorieSend((oldArray) => [...oldArray, options]);
    }
    console.log(categorieSend)
  };
  const handleOpenModal = (data: any, type: string, index: number) => {
    setDataSendModal(data);
    setOpenModal(true);
    setTitreModal(type);
    setIndexData(index);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleChangeCategoryName = (e: any) => {
    setcategoryNewName(e.target.value);
  };
  const handleUpdateDid = (category: any, didData: any, type: string) => {
    let dataSend;
    if (type === "Did") {
      dataSend = {
        did: didData.did,
        categories: category
          .filter((x: any) => x.checked)
          .map((el: any) => {
            return el.idCategory;
          }),
      };
    } else {
      dataSend = {
        name: categoryNewName,
        category: [didData["@id"]],
        subCategories: category
          .filter((x: any) => x.checked)
          .map((el: any) => {
            return el.idCategory;
          }),
      };
    }
    const dataPost: RequestInit = {
      method: "PUT",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    console.log(dataSend)
    console.log(process.env.REACT_APP_API_URL + didData["@id"] + "/put", dataPost)
    fetch(process.env.REACT_APP_API_URL + didData["@id"] + "/put", dataPost)
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          setError("ERROR");
          return "ERROR";
        }
        return res.json();
      })
      .then(
        (result) => {
          if (type === "did") {
            did[indexData].subCategories = category.filter(
              (x: any) => x.checked
            );
            handleClose();
            setDid(did);
          } else {
            categoriesList[indexData].name = categoryNewName;
            categoriesList[indexData].subCategories = category.filter(
              (x: any) => x.checked
            );
            handleClose();
            setCategoriesList(categoriesList);
          }

          setError(null);
        },
        (err) => {
          setIsLoading(false);
          setError(err);
        }
      );
  };

  return (
    <>
      <div className="container-fluid blck-comparatif">
        <h3 className="text-center title">
          Ajouter ou modifier des catégories
        </h3>
        <div className="mt-6">
          {isLoaded ? (
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-body ">
                        <Loading />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid addcategorie">
              <Tabs>
                <TabList>
                  <Tab>Categorie</Tab>
                  <Tab>Did</Tab>
                </TabList>
                <TabPanel className="row">
                  <div
                    className="col-md-4 card container mt-5"
                  >
                    <h3 className="title-h3 mb-3">Ajout catégorie</h3>
                    <form
                      className={classes.root + " row"}
                      noValidate
                      autoComplete="off"
                      onSubmit={(e) =>
                        handleSubmit(e, "/api/categories/create")
                      }
                    >
                      <div className="container">
                      <TextField
                        id="outlined-name"
                        label="Name"
                        type="search"
                        variant="outlined"
                        className="col-md-6"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                      />
                      <TextField
                        id="outlined-script"
                        label="Script"
                        type="search"
                        variant="outlined"
                        className="col-md-6"
                        autoFocus
                        onChange={(e) => setScript(e.target.value)}
                      />
                      </div>
                      <div className="col-md-12 row checkoptioncat">
                        <div className="col-md-6">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isSubMenu}
                                color="primary"
                                name="subCategory"
                                onChange={onChangeCheck}
                              />
                            }
                            label="Ajouter des sous catégories"
                          />

                          {isSubMenu && (
                            <div className="form-group col-md-12">
                              <label className="text-center">
                                Sous categories:
                              </label>
                              {
                                <FormControl style={{ marginLeft: "5%" }}>
                                  <Select
                                    onChange={handleSubCategory}
                                    defaultValue=""
                                  >
                                    {categorieList.map((q: any, i: any) => {
                                      return (
                                        <MenuItem key={i + "tS"} value={q}>
                                          {q.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              }
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isParent}
                                color="primary"
                                name="subCategory"
                                onChange={onChangeCheckParent}
                              />
                            }
                            label="Ajouter un Parent"
                          />

                          {isParent && (
                            <div className="form-group">
                              <label className="text-center">Parent:</label>
                              {
                                <FormControl style={{ marginLeft: "5%" }}>
                                  <Select
                                    onChange={handleCategory}
                                    defaultValue=""
                                  >
                                    {categorieList.map((q: any, i: any) => {
                                      return (
                                        <MenuItem key={i + "tS"} value={q}>
                                          {q.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              }
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row container">
                      <button
                        type="submit"
                        className={
                          !isLoaded
                            ? "btn-grad col-md-3"
                            : "btn-disable col-md-3"
                        }
                      >
                        Valider
                      </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className="col-md-7 card container mt-5"
                  >
                    <h3 className="title-h3">Modifier catégorie</h3>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Catégories</TableCell>
                            <TableCell>Sous catégories</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {
                          <TableBody>
                            {categoriesList.map((el: any, index: number) => {
                              return (
                                <>
                                  <TableRow key={index + "categorie"}>
                                    <TableCell> {el.name}</TableCell>
                                    <TableCell>
                                      {el.subCategories.map(
                                        (list: any, ind: number) => {
                                          return (
                                            <>
                                              <p key={ind + "name"}>
                                                {list.name}
                                              </p>
                                            </>
                                          );
                                        }
                                      )}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        handleOpenModal(el, "Categorie", index)
                                      }
                                    >
                                      Modifier
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        }
                      </Table>
                    </TableContainer>
                    <ModalComponent
                      show={openModal}
                      handleClose={handleClose}
                      data={dataSendModal}
                      categories={categorieList}
                      handleUpdateDid={handleUpdateDid}
                      handleChange={handleChangeCategoryName}
                      titre={titreModal}
                    />
                  </div>
                </TabPanel>
                <TabPanel className="row">
                  <div className="row">
                    <div className="col-md-5 card container mt-5">
                      <h3 className="title-h3">Ajout catégorie sur un DID</h3>
                      <div className="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isDidCreate}
                            color="primary"
                            name="didCreate"
                            onChange={onChangeCheckisDidCreate}
                          />
                        }
                        label="Créer un Did"
                      />
                      </div>
                      
                      {!isDidCreate ? (
                        <form
                          className="row  mt-6"
                          onSubmit={(e) => handleSubmit(e, "/api/did/create")}
                        >
                          <Autocomplete
                            id="combo-box"
                            options={did}
                            blurOnSelect
                            getOptionLabel={(option: any) => option.did}
                            style={{ width: 300 }}
                            className="didcategorie"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Did"
                                variant="outlined"
                              />
                            )}
                            onChange={(event, value) => setDidSend(value)}
                          />
                          <div className="col-md-4 didcatform ">
                            <FormControl className={classes.formControl}>
                              <InputLabel id="categorieList">
                                Categories
                              </InputLabel>
                              <Select
                                labelId="categorieList"
                                id="categorie_multiple_list"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                input={<Input id="select-multiple-categorie" />}
                                renderValue={(selected: any) => (
                                  <div>
                                    {(selected as any).map((value: any) => (
                                      <Chip
                                        key={value}
                                        label={value}
                                        className={classes.chip}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {categorieList.map((name: any, index) => (
                                  <MenuItem
                                    key={index + "name"}
                                    value={name.name}
                                    onClick={() => getData(name)}
                                  >
                                    {name.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="row text-center">
                            <button
                              type="submit"
                              className={
                                !isLoaded
                                  ? "btn-grad col-md-2"
                                  : "btn-disable col-md-2"
                              }
                            >
                              Valider
                            </button>
                          </div>
                        </form>
                      ) : (
                        <form
                          className="row  mt-4 ml-2"
                          onSubmit={(e) => handleSubmit(e, "/api/did/create")}
                        >
                          <div className="row m-1">
                          <input
                            className="col-md-4 p-1"
                            type="number"
                            name="did"
                            placeholder="Did"
                            onKeyDown={(e) =>
                              /[\+\-\.\,]$/.test(e.key) && e.preventDefault()
                            }
                            onChange={(e) => setDidSend(e.target.value)}
                          />
                          </div>
                          <div className="row">
                          <button
                            type="submit"
                            className={
                              !isLoaded
                                ? "btn-grad col-md-2"
                                : "btn-disable col-md-2"
                            }
                          >
                            Valider
                          </button>
                          </div>
                          
                        </form>
                      )}
                    </div>
                    <div className="col-md-6 card container mt-5">
                      <h3 className="title-h3">
                        Modifier un catégorie ou un DID
                      </h3>

                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Did</TableCell>
                              <TableCell>Catégories</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          {
                            <TableBody>
                              {did.map((el: any, index: number) => {
                                return (
                                  <>
                                    <TableRow key={index + "did"}>
                                      <TableCell> {el.did}</TableCell>
                                      <TableCell>
                                        {el.categories.map(
                                          (list: any, ind: number) => {
                                            return (
                                              <>
                                                <p key={ind + "name"}>
                                                  {list.name}
                                                </p>
                                              </>
                                            );
                                          }
                                        )}
                                      </TableCell>
                                      <TableCell
                                        onClick={() =>
                                          handleOpenModal(el, "Did", index)
                                        }
                                      >
                                        Modifier
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </TableBody>
                          }
                        </Table>
                      </TableContainer>
                    </div>
                    <ModalComponent
                      show={openModal}
                      handleClose={handleClose}
                      data={dataSendModal}
                      categories={categorieList}
                      handleUpdateDid={handleUpdateDid}
                      titre={titreModal}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AddCategories;
