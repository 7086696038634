import {FC, ReactElement } from "react";
import React from "react";
import ACDResultTab from "./ACDResultTab";
import { Switch, Route } from 'react-router-dom';
import ACDChartResult from "./ACDChartResult";
import Analyse from "../../Analyse/Analyse";
import AgentRankingResult from "./agentRankingResult";
const ACDResult : FC<{data: object, dataAnalyse: object, dataAgentRanking:object}> = ({ data, dataAnalyse, dataAgentRanking}) : ReactElement => {
    return   <Switch>
            <Route path="/acd-dids-daterange" component={() => <ACDResultTab datas={data} />} />
            <Route path="/acd-graph" component={() => <ACDChartResult datas={data} />} />
            <Route path="/analyse" component={() => <Analyse datas={dataAnalyse} />} />
            <Route path="/agent-ranking" component={() => <AgentRankingResult datas={dataAgentRanking} />} />

    </Switch>
}
export default React.memo(ACDResult);