import React from 'react';
import { TableBody, TableRow, TableCell } from '@mui/material';
import Loader from "../Recurrence/Loader";
import { durationToSeconds, secondsToDuration } from '../../Tools/utility';



type ImprovedTableBodyProps = {
  loading: boolean;
  tableauData: any;
};

const ImprovedTableBody: React.FC<ImprovedTableBodyProps> = ({ loading, tableauData }) => {
  return (
    <TableBody>
      {loading ? (
        <Loader />
      ) : (
        Object.keys(tableauData).map((key) => {
          let totalDurationServices = 0;
          let totalDurationWaiting = 0;

          tableauData[key].forEach((call: any) => {
            totalDurationServices += durationToSeconds(call.duration_services || '00:00:00');
            totalDurationWaiting += durationToSeconds(call.duration_waiting || '00:00:00');
          });

          const totalDurationServicesFormatted = secondsToDuration(totalDurationServices);
          const totalDurationWaitingFormatted = secondsToDuration(totalDurationWaiting);

          return (
            <TableRow key={key}>
              <TableCell className="text-center">{key}</TableCell>
              <TableCell className="text-center">{tableauData[key].length}</TableCell>
              {/* <TableCell className="text-center">{0}</TableCell> */}
              <TableCell className="text-center">{totalDurationServicesFormatted}</TableCell>
              <TableCell className="text-center">{totalDurationWaitingFormatted}</TableCell>
            </TableRow>
          );
        })
      )}
    </TableBody>
  );
};

export default ImprovedTableBody;
