import React, { useState } from 'react';
import Loading from "../spinner/spinner";
import { DateRangePicker } from "react-date-range";
import { fr } from "date-fns/locale";
import { moveDay, convert } from "../librairies/dateFunction";
import "../../assets/css/app.css";
import ComparatifResult from './ComparatifResult';

type dateData = {
  startDate: Date,
  endDate: Date,
  key: string
}
const Comparatif = () => {
  const [dataTMC, setDataTMC] = useState({ datasets: [{}] });
  const [dataCALLS, setDataCALLS] = useState({ datasets: [{}] });
  const [isQuerying, setIsQuerying] = useState<boolean>(false)
  const [isOneDay, setIsOneDay] = useState<boolean>(false)
  const [isShowResult, setShowResult] = useState<boolean>(false)
  const [date, setDate] = useState<dateData>(
    {
      startDate: moveDay(-7),
      endDate: new Date(),
      key: "selection",
    },
  );
  const fetchData = async (e: boolean) => {
    try {
      setIsQuerying(true)
      let url = `${process.env[ e ? 
          'REACT_APP_TELON_CUMUL_API_URL':
          'REACT_APP_TELON_COMPARISON_API_URL'
        ]}?startDate=${convert(date.startDate.toString())}&endDate=${convert(date.endDate.toString())}`
      console.log(process.env['REACT_APP_TELON_CUMUL_API_URL']);
      await fetch( url || '').then(res => res.json()).then(result => {
        setIsQuerying(false);
        setShowResult(true)
        setDataTMC(result);
        const a = JSON.parse(JSON.stringify(result))
        a.datasets.map((d: { parsing: { yAxisKey: string } }) => d.parsing.yAxisKey = 'calls')
        setDataCALLS(a)

      });
    } catch (error) {
      console.error("error", error);
    }
  };

  return <>
    <div className="container-fluid blck-comparatif">
        <h3 className="text-center title">COMPARATIF</h3>
        <div className="row">
        <div className="col-md-5" style={{ margin: "20px auto" }}>  
          <div className="card" >
            <div className="card-body">
              <h2 className="text-center title-dateRange">Choisir la période</h2>
              <DateRangePicker
                  onChange={(item: any) => {
                    setDate(item.selection)
                    if(item.selection.startDate.getTime() === item.selection.endDate.getTime()) setIsOneDay(true)
                    else setIsOneDay(false)
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={[date]}
                  maxDate={new Date()}
                  locale={fr}
              />
            </div>
          <div>
        </div>
        </div>
       { !isQuerying ? 
          !isOneDay ? <button className="btn-grad" onClick={()=>fetchData(false)}>Valider</button>: 
          <>
            <div className='d-inline'>
              <button className="btn-grad" onClick={()=>fetchData(true)}>Cumul de données</button>
              <button className="btn-grad" onClick={()=>fetchData(false)}>Données horaires</button>
            </div>
          </>: null}
        </div>
   
      </div>
    </div>
    {
      isQuerying ? <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-body ">
                  <Loading />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : null
    }
    {isShowResult ?
      <div className="container-fluid">
        <div className="row">
          <div className="container user-acddating col-md-10">
                <ComparatifResult
                titre="TMC"
                dataTMC={dataTMC}
                dataCALLS={dataCALLS}
                titre2="Nombre d'appels"
                dataCALLSDetails= {dataCALLS.datasets}
                key="index"
                />
          </div>
        </div>
      </div> : null}
   
  </>
}
export default Comparatif
