import React from 'react';

interface Props {
  datas: any[];
  dataInit: any[];
  GraphDataInit: any;
  transformTableDataVetoStat: (data: any[]) => any;
  formeDataVeto: (data: any) => any;
  setTableauData: (data: any) => void;
  setGraphData: (data: any) => void;
  setTitre: (titre: string) => void;
}

const ServiceSelector: React.FC<Props> = ({
  datas,
  dataInit,
  GraphDataInit,
  transformTableDataVetoStat,
  formeDataVeto,
  setTableauData,
  setGraphData,
  setTitre,
}) => {
  const handleServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    
    const updateData = (filterCondition: (x: any) => boolean, title: string) => {
      const filteredData = datas.filter(filterCondition);
      const transformedData = transformTableDataVetoStat(filteredData);
      setTableauData(transformedData);
      setGraphData(formeDataVeto(transformedData));
      setTitre(title);
    };
    
    switch (value) {
      case "tous les services":
        setTableauData(dataInit);
        setGraphData(GraphDataInit);
        setTitre("tous les services");
        break;
  
      case "VETERINAIRE":
        updateData((x: any) => x.did.includes('0893020200'), "VETERINAIRE");
        break;
  
      case "VETO_ANNUAIRE":
        updateData((x: any) => x.did.includes('0893020600') || x.did.includes('0893020700') || x.did.includes('0893020500'), "VETO_ANNUAIRE");
        break;
  
      default:
        console.error("Service non reconnu");
        break;
    }
  };
  

  return (
    <select
      className="col-md-4"
      name="services"
      id="services"
      onChange={handleServiceChange}
    >
      <option key="null" value="tous les services">
        Selectionez un service
      </option>
      
        <option key='VETO_ANNUAIRE' value='VETO_ANNUAIRE'>
          VETO_ANNUAIRE
        </option>
        <option key='VETERINAIRE' value='VETERINAIRE'>
          VETERINAIRE
        </option>
     
    </select>
  );
};

export default ServiceSelector;
