import React, { FC, ReactElement, } from "react";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

const RatingOption: FC<{
  readOnly: boolean;
  value: number | null;
  setRating: any;
  ind: number;
}> = ({ readOnly, value, setRating, ind }): ReactElement => {
  return (
    <div className={"ratingFilter"}>
      <Box component="fieldset" mb={3} borderColor="transparent">
        {readOnly ? (
          <Rating
            name="read-only"
            precision={0.5}
            value={value}
            readOnly={true}
            size="large"
          />
        ) : (
          <Rating
            size="large"
            value={value}
            precision={0.5}
            onChange={(event, newValue) => {
              setRating(event, newValue);
            }}
            name="size-large"
          />
        )}
      </Box>
    </div>
  );
};
export default RatingOption;
