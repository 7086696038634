import {
  FETCH_HARDWARE_FAILURE,
  FETCH_HARDWARE_REQUEST,
  FETCH_HARDWARE_MUNING_SUCCESS,
  FETCH_HARDWARE_NAGIOS_SUCCESS,
} from "./hardwareType";

const initialState = {
nagios: [],
muning: [],
  error: null,
  loading: false,
};
const hardwareReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_HARDWARE_REQUEST:
      return { ...state, loading: true };
    case FETCH_HARDWARE_MUNING_SUCCESS:
      return {
        muning: action.payload,
        error: null,
        loading: false,
      };
      case FETCH_HARDWARE_NAGIOS_SUCCESS:
        return {
          nagios: action.payload,
          error: null,
          loading: false,
          muning: state.muning
        };
    case FETCH_HARDWARE_FAILURE:
      return {
        nagios: [],
        muning: [],
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default hardwareReducer;
