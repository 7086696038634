import {
  FETCH_HARDWARE_FAILURE,
  FETCH_HARDWARE_REQUEST,
  FETCH_HARDWARE_MUNING_SUCCESS,
  FETCH_HARDWARE_NAGIOS_SUCCESS,
} from "./hardwareType";

export const fetchDataHardwareRequest = () => {
  return {
    type: FETCH_HARDWARE_REQUEST,
  };
};

export const fetchDataMuningSuccess = (muning: any) => ({
  type: FETCH_HARDWARE_MUNING_SUCCESS,
  payload: muning,
});
export const fetchDataNagiosSuccess = (nagios: any) => ({
  type: FETCH_HARDWARE_NAGIOS_SUCCESS,
  payload: nagios,
});
export const fetchDataHardwareFailure = (error: string) => ({
  type: FETCH_HARDWARE_FAILURE,
  payload: error,
});

export const PostDataHardware = (url: string, credentials: any) => {
  return async (dispatch: any) => {
    dispatch(fetchDataHardwareRequest());
    //@ts-ignore
    const urlData = process.env.REACT_APP_API_URL + url;
    fetch(urlData, credentials)
      .then((res) => {
        if (!res.ok) {
        }
        return res.json();
      })
      .then(
        (result) => {
            const a = Object.keys(result.muning).map((key) => [
                String(key),
                result.muning[key],
              ]);
              const b = Object.keys(result.nagios).map((key) => [
                String(key),
                result.nagios[key],
              ]);
          dispatch(fetchDataMuningSuccess(a));
          dispatch(fetchDataNagiosSuccess(b));
        },
        (err) => {
          dispatch(fetchDataHardwareFailure(err));
        }
      );
  };
};
