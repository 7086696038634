import React, { useContext, useEffect, useState } from "react";
import Loading from "../spinner/spinner";
import {
  convertArrayToString,
  convertMinToSec,
  getSelectDate,
  convertSecToH,
} from "../librairies/dateFunction";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "../../assets/css/app.css";
import moment from "moment";
import jwt_decode from "jwt-decode";
import "moment/locale/fr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckboxComponent from "../librairies/checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "../../assets/scss/agent.scss";
import Player from "../player/player";
import DurationPicker from "react-duration-picker";
import RatingOption from "../rating/rating";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Rating from "@material-ui/lab/Rating";
import { TokenContext } from "../../App";

const Agent = () => {
  const userInfo: any = jwt_decode(useContext(TokenContext));
  const token = useContext(TokenContext);
  const [information] = useState<Array<any>>(userInfo.services);
  const [tokenService, settokenService] = useState<Array<any>>(
    Object.keys(userInfo.services)
  );
  const [dateDebut, setdateDebut] = useState<any>();
  const [dateFin, setdateFin] = useState<any>();
  const [agentName, setAgentName] = useState<string>("");
  const [agentList, setAgentList] = useState<Array<any>>([]);
  const [agentDispo, setAgentDispo] = useState<Array<any>>([]);
  const [error, setError] = useState<any>(null);
  const [didSend, setdidSend] = useState<Array<string>>([]);
  const [isLoaded, setIsLoading] = useState<Boolean>(false);
  const [items, setItems] = useState<any>();
  const [tableTitle] = useState<Array<string>>([
    "Début",
    "Unique ID",
    "Durée",
    "Agent",
    "Service",
    "Did",
    "Ecoute",
    "Note",
    "Commentaire",
  ]);
  const [tokenDid, settokenDid] = useState<Array<any>>([]);
  const [serviceTokenShow, setserviceTokenShow] = useState<Array<string>>([]);
  const [showData, setshowData] = useState<boolean>(false);
  const [timeDebut, setTimeDebut] = useState<any>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [timeFin, setTimeFin] = useState<any>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const handleChangeDebut = (time: any) => {
    setTimeDebut(time);
  };
  const handleChangeFin = (time: any) => {
    setTimeFin(time);
  };
  const handleAgent = (event: any) => {
    setshowData(true);
    setAgentName(event.target.value);
  };
  const [value, setValue] = useState<number | null>(0);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const setRating = (event: any, value: number | null) => {
    for (let i = 0; i < items.length; i++) {
      if (
        items[i][1].data[
          items[i][1].data.findIndex(
            (el: { uniqueid: any }) =>
              el.uniqueid === event.target.id.substr(11, 17)
          )
        ]
      ) {
        items[i][1].data[
          items[i][1].data.findIndex(
            (el: { uniqueid: any }) =>
              el.uniqueid === event.target.id.substr(11, 17)
          )
        ].star = value;
        return items;
      }
      setValue(value);
    }
  };
  const setCommentData = (e: any) => {
    for (let i = 0; i < items.length; i++) {
      if (
        items[i][1].data[
          items[i][1].data.findIndex(
            (el: { uniqueid: any }) => el.uniqueid === e.target.id
          )
        ]
      ) {
        items[i][1].data[
          items[i][1].data.findIndex(
            (el: { uniqueid: any }) => el.uniqueid === e.target.id
          )
        ].commentaire = e.target.value;
        return items;
      }
    }
  };
  useEffect(() => {
    setItems(items);
  }, [value]);

  const handleDid = async () => {
    const data = {
      query: {
        start_time: [
          {
            gte: moment(dateDebut).format("YYYY-MM-DD"),
            lte: moment(dateFin).format("YYYY-MM-DD"),
          },
        ],
        duration_int: {
          gte:
            Number(convertMinToSec(timeDebut.minutes)) +
            Number(timeDebut.seconds),
          lt:
            Number(convertMinToSec(timeFin.minutes)) + Number(timeFin.seconds),
        },
        did: didSend,
      },
      attributes: {
        page: 1,
        query_type: await getSelectDate(dateDebut._d, dateFin._d),
        no_cache: true,
      },
      response_fields: ["record", "duration_int", "did"],
    };
    getData(data);
  };
  useEffect(() => {
    let didRsp: Array<string> = [];
    for (let i = 0; i < information.length; i++) {
      didRsp.push(information[i].did);
    }
    setdidSend(didRsp);
  }, [information]);
  const handleAgentName = (name: string) => {
    setshowData(true);
    setAgentName(name);
  };

  const deleteService = (name: string) => {
    setserviceTokenShow(serviceTokenShow.filter((el: any) => el !== name));
    settokenService((oldArray) => [...oldArray, name]);
  };
  const mergeDedupe = (arr: any) => {
    return [...new Set([].concat(...arr))];
  };

  const handleSelectService = (event: any) => {
    if (event.target.value !== "SERVICE") {
      setserviceTokenShow([...serviceTokenShow, event.target.value]);
      settokenService(
        tokenService.filter((x: any) => !serviceTokenShow.includes(x))
      );
    }
  };
  useEffect(() => {
    didSend.forEach((d: string) => {
      tokenDid
        .filter((x: any) => x.label === d)
        .map((el: any) => (el["checked"] = true));
      settokenDid(tokenDid);
    });
    const response = tokenDid
      .filter((x) => x.checked)
      .map((el: any) => {
        return el.label;
      });
    setdidSend(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenDid]);
  const onChangeCheck = () => {
    tokenDid.filter((x) => x.checked);
  };
  const toggle = (index: any) => {
    tokenDid.splice(index, 1, {
      label: tokenDid[index].label,
      checked: !tokenDid[index].checked,
    });
    const response = tokenDid
      .filter((x) => x.checked)
      .map((el: any) => {
        return el.label;
      });
    setdidSend(response);
  };
  useEffect(() => {
    let allDid: Array<string> = [];
    for (let i: number = 0; i < serviceTokenShow.length; i++) {
      //@ts-ignore
      allDid.push(information[serviceTokenShow[i]]);
    }
    settokenDid(
      mergeDedupe(allDid).map((el: any) => {
        return { label: el, checked: false };
      })
    );
  }, [serviceTokenShow, information]);
  const getData = (data: any) => {
    setIsLoading(true);
    const dataPost: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(process.env.REACT_APP_API_URL + "/api/getCallStat", dataPost)
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          setError("ERROR");
          return "ERROR";
        }
        return res.json();
      })
      .then(
        (result) => {
          const a = Object.keys(result).map((key) => [
            String(key),
            result[key],
          ]);
          setItems(a);
          setIsLoading(false);
          setError(null);
        },
        (err) => {
          setIsLoading(false);
          setError(err);
        }
      );
  };

  useEffect(() => {
    const getAllAgent = () => {
      const config: RequestInit = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      fetch(process.env.REACT_APP_API_URL + "/api/agents", config)
        .then((res) => {
          if (!res.ok) {
            setIsLoading(false);
            setError("ERROR");
            return "ERROR";
          }
          return res.json();
        })
        .then(
          (result) => {
            setAgentList(result);
            setIsLoading(false);
            setError(null);
          },
          (err) => {
            setIsLoading(false);
            setError(err);
          }
        );
    };
    getAllAgent();
    if (agentList) {
      const result = agentList.filter((person: any) => {
        return (
          person.firstName.toLowerCase().includes(agentName) ||
          person.lastName.toLowerCase().includes(agentName)
        );
      });
      setAgentDispo(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentName, token]);
  const maxDateDebut = (current: any) => {
    return current.isBefore(moment());
  };
  const maxDateFin = (current: any) => {
    return (
      current.isBefore(moment()) &&
      current.isAfter(moment(dateDebut).subtract(1, "day"))
    );
  };
  const setNote = (row: any, event: any) => {
    event.target.className = "btn-disable";
    const data = {
      uniqueid: row.uniqueid,
      rate: row.star,
      comment: row.commentaire
    };
    const dataPost: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(process.env.REACT_APP_API_URL + "/api/rate", dataPost)
      .then((res) => {
        if (!res.ok) {
          setIsLoading(false);
          setError("ERROR");
          return "ERROR";
        }
        return res.json();
      })
      .then(
        (result) => {
          for (let i = 0; i < items.length; i++) {
            if (
              items[i][1].data[
                items[i][1].data.findIndex(
                  (el: { uniqueid: any }) => el.uniqueid === row.uniqueid
                )
              ]
            ) {
              items[i][1].data[
                items[i][1].data.findIndex(
                  (el: { uniqueid: any }) => el.uniqueid === row.uniqueid
                )
              ].rate = row.star;
              items[i][1].data[
                items[i][1].data.findIndex(
                  (el: { uniqueid: any }) => el.uniqueid === row.uniqueid
                )
              ].comment = row.commentaire;
              setItems(items);
            }
            setValue(row.star);
            toast.success("Note ajouté", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          setError(null);
        },
        (err) => {
          event.target.className = "btn-grad";
          toast.error("Erreur", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setError(err);
        }
      );
  };
  return (
    <>
      <div className="container-fluid">
        <ToastContainer />
        <h3 className="text-center title">
          Chercher un appel selon de multiples critères
        </h3>
        <div className="row">
          <div
            className="container user-acddating col-md-8"
            style={{ margin: "20px auto" }}
          >
            <div className="card card-5">
              <div className="card-heading">
                <h2 className="text-center">Faites votre choix</h2>
                <div className="alert alert-dark" role="alert">
                  Choisissez
                  <ol>
                    <li>un intervalle dans lequel l'appel a commencé,</li>
                    <li>un intervalle pendant lequel l'appel a duré </li>
                    <li>le service appelé</li>
                  </ol>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="card-group-item col-md-6 formAcdDateSystem">
                    <div className="card-body date col-md-12">
                      <div className="col-md-6 diplay-flex">
                        <div>L'appel a commencé entre le...</div>
                        <div>
                          <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={setdateDebut}
                            isValidDate={maxDateDebut}
                            locale="fr-ca"
                          />
                        </div>
                      </div>
                      {dateDebut ? (
                        <div className="col-md-6 diplay-flex">
                          <div>... et le.</div>
                          <div>
                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={setdateFin}
                              isValidDate={maxDateFin}
                              locale="fr-ca"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="card-body minute row">
                      <div className="col-md-6 font-sz-fiften">
                        <div>L'appel a duré entre ... </div>
                        <DurationPicker
                          initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
                          onChange={handleChangeDebut}
                          noHours={true}
                        />
                      </div>
                      <div className="col-md-6 font-sz-fiften">
                        <div>... et</div>
                        <DurationPicker
                          initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
                          onChange={handleChangeFin}
                          noHours={true}
                        />
                      </div>
                    </div>
                    <div className="card-body agent d-none">
                      <div className="form-group">
                        <label>Prise en charge par l'agent :</label>
                        <input
                          style={{ marginLeft: "5%", width: "54%" }}
                          name="agent"
                          placeholder="Agent"
                          type="text"
                          value={agentName}
                          onChange={handleAgent}
                        />
                        {showData && agentDispo.length > 0 ? (
                          <ul
                            onMouseLeave={() => setshowData(false)}
                            className={`list-group ${
                              agentDispo.length > 7 ? " scroll" : null
                            }`}
                          >
                            {agentDispo.map((agent: any, index: number) => (
                              <li
                                className="list-group-item"
                                key={index + "ind"}
                                onClick={() => handleAgentName(agent.firstName)}
                              >
                                {agent.firstName} {agent.lastName}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="card-group-item col-md-6 formAcdDateSystem">
                    <ToastContainer />
                    <div className="card-body">
                      <div className="form-group">
                        <label>Le numéro appelé appartient au service:</label>
                        {tokenService.length > 0 && (
                          <FormControl style={{ marginLeft: "5%" }}>
                            <Select
                              onChange={handleSelectService}
                              defaultValue=""
                            >
                              {[...new Set(tokenService)].map(
                                (q: any, i: any) => {
                                  return (
                                    <MenuItem key={i + "tS"} value={q}>
                                      {q}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        )}
                      </div>
                      {serviceTokenShow.length > 0 ? (
                        <div className="input selectServiceACD">
                          <ul className="m-0">
                            {[...new Set(serviceTokenShow)].map(
                              (el: any, index: any) => {
                                return el ? (
                                  <li key={el + "service"}>
                                    {el}
                                    <span>
                                      <i
                                        className="nc-icon nc-simple-remove"
                                        onClick={() => deleteService(el)}
                                      ></i>
                                    </span>
                                  </li>
                                ) : null;
                              }
                            )}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    {serviceTokenShow.length > 0 ? (
                      <div className="card-body">
                        <div className="form-group">
                          <label>Did :</label>
                          <div className="input selectServiceACD">
                            {
                              <ul className="m-0">
                                {didSend.length > 0
                                  ? didSend.map((el: any, index: any) => {
                                      return el ? (
                                        <li key={index + "SERVICE"}> {el}</li>
                                      ) : null;
                                    })
                                  : null}
                              </ul>
                            }
                          </div>
                        </div>

                        {
                          <div
                            className={tokenDid.length > 20 ? "didScroll" : ""}
                          >
                            <CheckboxComponent
                              items={tokenDid}
                              className={"col-lg-4 col-md-4 col-xs-6"}
                              toggle={toggle}
                              onChangeCheck={onChangeCheck}
                            />
                          </div>
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {
                <button
                  className={
                    didSend.length > 0 &&
                    dateDebut &&
                    dateFin &&
                    timeDebut &&
                    timeFin &&
                    !isLoaded
                      ? "btn-grad"
                      : "btn-disable"
                  }
                  onClick={() => handleDid()}
                >
                  Afficher
                </button>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-12  col-lg-12 col-xl-12 col-md-12">
        {error !== null ? (
          <h2 className="alert alert-danger" role="alert">
            Une Erreur est survenue
          </h2>
        ) : isLoaded ? (
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-body ">
                      <Loading />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : items ? (
          <div className="card">
            <div className="card-body ">
              <h2 className="text-center">Résultat</h2>
              {items.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {tableTitle.map((el: string) => (
                          <TableCell key={el + "title"}>{el}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {
                      <TableBody className="audio-list-call">
                        {items.map((el: any, ind: number) =>
                          el[1].data.length > 0 ? (
                            el[1].data.map((row: any, indexData: number) => {
                              return (
                                <>
                                  <TableRow key={indexData + row.agent}>
                                    <TableCell component="th" scope="row">
                                      {el[0]}
                                    </TableCell>
                                    <TableCell>{row.uniqueid}</TableCell>
                                    <TableCell>
                                      {convertSecToH(row.duration_int)}
                                    </TableCell>
                                    <TableCell>{row.agent}</TableCell>
                                    <TableCell>
                                      {convertArrayToString([
                                        ...new Set(serviceTokenShow),
                                      ])}
                                    </TableCell>
                                    <TableCell>{row.did}</TableCell>
                                    <TableCell>
                                      {row.record !== "" && row.record ? (
                                        <Player src={row.record} />
                                      ) : null}
                                    </TableCell>
                                    {(userInfo.roles[0] === "ROLE_ADMIN" ||
                                      userInfo.roles.includes(
                                        "ROLE_SUPERVISEUR"
                                      )) &&
                                    row.record ? (
                                      <TableCell className="note-call">
                                        {
                                          <Rating
                                            size="large"
                                            value={
                                              row.rate !== null
                                                ? row.rate
                                                : row.star !== null
                                                ? row.star
                                                : 0
                                            }
                                            precision={0.5}
                                            onChange={(event, newValue) => {
                                              setRating(event, newValue);
                                            }}
                                            readOnly={
                                              row.rate !== null ? true : false
                                            }
                                            name={`size-large ${row.uniqueid}`}
                                          />
                                        }
                                      </TableCell>
                                    ) : (
                                      <TableCell></TableCell>
                                    )}
                                    {(userInfo.roles[0] === "ROLE_ADMIN" ||
                                      userInfo.roles.includes(
                                        "ROLE_SUPERVISEUR"
                                      )) &&
                                    row.record ? (
                                      <TableCell className="comment-call">
                                        <TableCell>
                                          {row.comment === null ? (
                                            <>
                                              <TextField
                                                id={`${row.uniqueid}`}
                                                multiline
                                                rows={3}
                                                onChange={(event) => {
                                                  setCommentData(event);
                                                }}
                                              />
                                              <button
                                                className={"btn-grad"}
                                                onClick={(e) =>
                                                  setNote(row, e)
                                                }
                                              >
                                               Noter et Commenter
                                              </button>
                                            </>
                                          ) : (row.comment)}
                                        </TableCell>
                                      </TableCell>
                                    ) : (
                                      <TableCell></TableCell>
                                    )}
                                  </TableRow>
                                </>
                              );
                            })
                          ) : (
                            <TableRow key={ind + el[0] + ind}>
                              <TableCell component="th" scope="row">
                                {el[0]}
                              </TableCell>
                              <TableCell className="text-center" colSpan={6}>
                                Il n'y a pas de résultat
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
              ) : (
                <h2 className="text-center">Aucun Résultat</h2>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Agent;
