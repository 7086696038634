import React, { FC, ReactElement, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Rating from "@material-ui/lab/Rating";

const AgentRankingResult: FC<{ datas: any }> = ({ datas }): ReactElement => {
  const [titleTab] = useState<Array<string>>(["Nom", "Note"]);
  const [rows] = useState<Array<any>>(
    datas.map((el: any, index:number) => {
      return { Numéro: "73204", Nom: el.key, Note: el.avg_rate.value };
    })
  );
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="text-center">Résultat</h2>
          {datas.length > 0 ? (
            <div className={datas.length > 8 ? "table" : ""}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {titleTab.map((el: string) => (
                        <TableCell key={el + "title"}>{el}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, ind) => (
                      <TableRow key={row.Numéro}>
                        <TableCell component="th" scope="row">{row.Nom}</TableCell>
                        <TableCell>
                          <Rating
                            name="read-only"
                            value={row.Note}
                            readOnly={true}
                            size="large"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <h2 className="text-center">Aucun Résultat</h2>
          )}
        </div>
      </div>
    </>
  );
};

export default AgentRankingResult;
