/* eslint-disable react-hooks/exhaustive-deps */
import { Table, TableCell, TableContainer, TableFooter, TableHead } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import Datetime from "react-datetime";
import Loader from "../Recurrence/Loader";
import {  formeDataVeto,  getDataVetoStats,transformTableDataVetoStat } from "../CallAnalisis/CallAnalysisService";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ImprovedTableBody from "./ImprovedTableBody";
import GraphVeto from "./GraphVeto";
import ServiceSelector from "./ServiceSelector";
import moment from "moment";

const CallAnalysis:React.FC = ()=>{
    const initialDate = new Date();
    initialDate.setMonth(initialDate.getMonth() - 1);
	const [date1, setDate1] = useState<any>(initialDate);
	const [date2, setDate2] = useState<any>(new Date());
    const [tableauData, setTableauData] = useState<any>([]);
    const [GraphData, setGraphData] = useState<any>([]);
    const [GraphDataInit, setGraphDataInit] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showGraph, setShowGraph] = useState<boolean>(true);
    const [showTableau, setShowTableauData] = useState<boolean>(true);
    const [datas, setDatas] = useState<any>([]);
    const [dataInit, setDataInit] = useState<any>([]);
    const [titre, setTitre] = useState<string>(" tous les services")
    

    const isValidDate1 = (date: Date | null) => {
		if (!date2) return true;
		return date !== null && date <= new Date();
	}
	const isValidDate2 = (date: Date | null) => {
		if (!date1) return true;
		return date !== null && date >= date1 && date <= new Date();
	}
    const fetchData = async (startDate = date1, endDate = date2) => {
        if (date1 && date2) {
          setLoading(true);
          const dt = await getDataVetoStats(startDate, endDate);
          setDatas(dt);
          const tableData = transformTableDataVetoStat(dt);
          setDataInit(tableData)
          setTableauData(tableData);
          setGraphData(formeDataVeto(tableData));
          setGraphDataInit(formeDataVeto(tableData));
          setLoading(false);
        }
      };
     
      const convertDAte1 = (d: any) => {
        if (d instanceof Date || moment(d, "YYYY-MM-DD", true).isValid()) {
            setDate1(moment(d).toDate()); // Convertit en objet Date
        } else {
            console.error("La date n'est pas valide.");
        }
    }
      const convertDAte2 = (d:any) => {
        if (d instanceof Date || moment(d, "YYYY-MM-DD", true).isValid()) {
            setDate2(moment(d).toDate()); 
            fetchData(date1, moment(d).format("YYYY-MM-DD") );
        } else {
            console.error("La date n'est pas valide.");
        }
       
      }
    
      useEffect(() => {
        fetchData();
      }, []);
    return <>
        <div className="card">
            <div className="card-body">
                <h3 className="text-center title">{titre}</h3>
                <div className="row">
                        
                            Date1 
                            <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={(d) => convertDAte1(d)}
                            locale="fr-ca"
                            timeFormat={false}
                            className='col-md-2'
                            isValidDate={isValidDate1}
                            />
                        
                        
                            Date 2
                            <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={(d)=> convertDAte2(d)}
                            locale="fr-ca"
                            timeFormat={false}
                            isValidDate={isValidDate2}
                            className='col-md-2'
                            />
                        
                        <ServiceSelector
                            datas={datas}
                            dataInit={dataInit}
                            GraphDataInit={GraphDataInit}
                            transformTableDataVetoStat={transformTableDataVetoStat}
                            formeDataVeto={formeDataVeto}
                            setTableauData={setTableauData}
                            setGraphData={setGraphData}
                            setTitre={setTitre}
                        />
                </div>
            </div>
        </div>
        <div className="card">
            <section className="card-body col-md-12">
                <IconButton className="btn btn-primary col-md-12" onClick={() => setShowGraph(!showGraph)}>
                    {!showGraph?<VisibilityOffIcon/>:<VisibilityIcon/>}
                    Statistiques veto du {date1.toLocaleDateString()} au {date2.toLocaleDateString()}
                </IconButton>
                <p style={{color:"white"}}>----</p>
                {
                    loading?<Loader/>:showGraph?  <GraphVeto graphData={GraphData} />: null
                }
            </section>
        </div>
        <div className='card' style={{  position: '-webkit-sticky', top: 0, zIndex: 100 }}>
            <div className='row'  style={{position: 'sticky', bottom: 0, backgroundColor: `rgba(255, 255, 255, 1)`}}>
                <section className="card-body col-md-12">
                    <IconButton className="btn btn-primary col-md-12" onClick={() => setShowTableauData(!showTableau)}>
                        {!showTableau?<VisibilityOffIcon/>:<VisibilityIcon/>}
                        Liste des statistiques sur {titre} du {date1.toLocaleDateString()} au {date2.toLocaleDateString()}
                    </IconButton>
                    {showTableau?<TableContainer className='' style={{ height: 'calc(100vh - 50px)', overflowY: 'auto'}}>
                        <Table>
                            <TableHead style={{ position: 'sticky', top: 0,zIndex: 100, backgroundColor: `rgba(255, 255, 255, 1)` }}>
                                <TableCell className='text-center'>Jour</TableCell>
                                <TableCell className='text-center'>Nombre d'appels</TableCell>
                                {/* <TableCell className='text-center'>Nombre appel transfere</TableCell> */}
                                <TableCell className='text-center'>Temps total sur services</TableCell>
                                <TableCell className='text-center'>Temps sur file d'attente:</TableCell>
                                
                            </TableHead>
                            <ImprovedTableBody tableauData={tableauData} loading={loading} />
                            <TableFooter style={{position: 'sticky', bottom: 0, backgroundColor: `rgba(255, 255, 255, 1)`}}>
                                
                            </TableFooter>
                        </Table>
                    </TableContainer>:null}
                </section>
            </div>
        </div>
    </>
}

export default CallAnalysis;
