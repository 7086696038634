import {
  FETCH_DATERANGE_FAILURE,
  FETCH_DATERANGE_REQUEST,
  FETCH_DATERANGE_SUCCESS,
  FETCH_DATANALYSE_SUCCESS,
  FETCH_DATAGENTRANKING_SUCCESS
} from "./dateRangeType";

export const fetchDataRangeRequest = () => {
  return {
    type: FETCH_DATERANGE_REQUEST,
  };
};

export const fetchDataRangeSuccess = (dateRange: any) => ({
  type: FETCH_DATERANGE_SUCCESS,
  payload: dateRange,
});
export const fetchDataRangeFailure = (error: string) => ({
  type: FETCH_DATERANGE_FAILURE,
  payload: error,
});
export const fetchDataAnalyseSuccess = (dataAnalyse: any) => ({
  type: FETCH_DATANALYSE_SUCCESS,
  payload: dataAnalyse,
});
export const fetchDataAgentRaningSuccess = (data: any) => ({
  type: FETCH_DATAGENTRANKING_SUCCESS,
  payload: data,
});
export const PostDataRange = (url: string, credentials: any) => {
  return async (dispatch: any) => {
    dispatch(fetchDataRangeRequest());
    const urlData = process.env.REACT_APP_API_URL + url;
    fetch(urlData, credentials)
      .then((res) => {
        if (!res.ok) {
        }
        return res.json();
      })
      .then(
        (result) => {
          dispatch(fetchDataRangeSuccess(result));
        },
        (err) => {
          dispatch(fetchDataRangeFailure(err));
        }
      );
  };
};
export const PostDataAnalyse = (url: string, credentials: any) => {
  return async (dispatch: any) => {
    dispatch(fetchDataRangeRequest());
    const urlData = process.env.REACT_APP_API_URL + url;
    fetch(urlData, credentials)
      .then((res) => {
        if (!res.ok) {
        }
        return res.text();
      })
      .then(
        (result) => {
          dispatch(fetchDataAnalyseSuccess(result));
        },
        (err) => {
          dispatch(fetchDataRangeFailure(err));
        }
      );
  };
};
export const PostAgentRanking = (url: string, credentials: any) => {
  return async (dispatch: any) => {
    dispatch(fetchDataRangeRequest());
    const urlData = process.env.REACT_APP_API_URL + url;
    fetch(urlData, credentials)
      .then((res) => {
        if (!res.ok) {
        }
        return res.json();
      })
      .then(
        (result) => {
          dispatch(fetchDataAgentRaningSuccess(result));
        },
        (err) => {
          dispatch(fetchDataRangeFailure(err));
        }
      );
  };
};