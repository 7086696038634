import { useContext, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { connect } from "react-redux";
import { PostDataRange, PostDataAnalyse, PostAgentRanking } from "../../store";
import Loading from "../spinner/spinner";
import { fr } from "date-fns/locale";
import jwt_decode from "jwt-decode";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { convert, getSelectDate } from "../librairies/dateFunction";
import ACDResult from "./Results/ACDResult";
import "../../assets/css/app.css";
import CheckboxComponent from "../librairies/checkbox";
import RatingOption from "../rating/rating";
import { TokenContext } from "../../App";
import Checkbox from "@material-ui/core/Checkbox";

const AcdDateRange = (props: any) => {
  const token = useContext(TokenContext);
  const userInfo: any = jwt_decode(useContext(TokenContext));
  const [serviceList] = useState<Array<any>>(
    Object.keys(userInfo.services).map((el: any) => {
      return { label: el, checked: false };
    })
  );
  const [strict, setStrict] = useState<boolean>(false);

  const [getValueRanking, setgetValueRanking] = useState(null);
  const [servicesArray, setServicesArray] = useState(serviceList);
  const [serviceSend, setserviceSend] = useState<any>();
  const [serviceRanking, setserviceRanking] = useState<string>('');
  const [userDataDid] = useState<Array<any>>(
    userInfo.user_configurations.ALLOWED_DID.map((el: any) => {
      return { label: el, checked: false };
    })
  );
  
  const [numbersArray, setNumbersArray] = useState<Array<any>>(userDataDid);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [value, setValue] = useState<number | null>(0);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const setRating = (event: any, value: number | null) => {
    setValue(value);
    setReadOnly(!readOnly);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setserviceRanking((event.target as HTMLInputElement).value);
  };
  const [items, setItems] = useState<any>();
  const [did, setDid] = useState<string>();
  const [didSend, setdidSend] = useState<any>();

  const handleDid = async () => {
    const data = {
      query: {
        start_time: [
          {
            gte: convert(date[0].startDate.toString()),
            lte: convert(date[0].endDate.toString()),
          },
        ],
        did: didSend,
      },
      attributes: {
        page: 1,
        query_type: await getSelectDate(
          date[0].startDate.toString(),
          date[0].endDate.toString()
        ),
      },
    };
    const dataPost: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    did ? props.PostDataRange("/api/getCallStat", dataPost) : setItems([]);
  };
  const getDataAnalyse = (url: string) => {
    const data = {
      startAt: convert(date[0].startDate.toString()),
      endAt: convert(date[0].endDate.toString()),
      did: didSend,
    };
    const dataPost: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    props.PostDataAnalyse(url, dataPost);
  };
  const toggle = (index: any) => {

      numbersArray.splice(index, 1, {
        label: numbersArray[index].label,
        checked: !numbersArray[index].checked,
      });

      const response = numbersArray
        .filter((x) => x.checked)
        .map((el: any) => {
          return el.label;
        });
      setdidSend(response);
      setNumbersArray(numbersArray);
      setDid(
        JSON.stringify(response)
          .replaceAll(/,/gi, "-")
          .replaceAll(/"/gi, "")
          .slice(1, -1)
      );
    }
  const onChangeCheck = () => {
    numbersArray.filter((x) => x.checked)
  };
  const getRankingResponse = (url: string) => {
    setReadOnly(!readOnly);
    const data = {
      from: convert(date[0].startDate.toString()),
      end: convert(date[0].endDate.toString()),
      acd: value,
      service: serviceRanking,
      strict: !strict
    };
   const dataPost: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    props.PostDataAgentRanking(url, dataPost);

    setValue(0);
  };
  const titre_acd_dids_range =
    "Afficher le TMC (ou ACD) d'un ou plusieurs DIDs pour une période choisie";
  const titre_agent_ranking = "Liste des agents selon leur note";
  return (
    <>
      <div className="container-fluid">
        <h3 className="text-center title">
          {window.location.href.includes("/acd-dids-daterange")
            ? titre_acd_dids_range
            : window.location.href.includes("/analyse")
            ? "ANALYSE"
            : window.location.href.includes("/agent-ranking")
            ? titre_agent_ranking
            : titre_acd_dids_range}
        </h3>

        <div className="row">
          <div
            className="col-md-5 acdDateRange"
            style={{ margin: "20px auto" }}
          >
            <div className="card">
              <div className="card-body">
                <h2 className="text-center title-dateRange">
                  Choisir la période
                </h2>
                <div className="alert alert-dark" role="alert">
                  1 - Choisissez ci-dessous l'intervalle de dates pour lequel
                  vous souhaitez avoir les statistiques d'appels
                </div>
                <DateRangePicker
                  onChange={(item: any) => setDate([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={date}
                  maxDate={new Date()}
                  locale={fr}
                />
              </div>
              <div></div>
            </div>
          </div>

          <div
            className={`acdDateRange col-7 col-lg-7 col-xl-7 col-md-7 } `}
            style={{ margin: "20px auto" }}
          >
            <div className="card">
              <div className="card-body">
                {window.location.href.includes("/agent-ranking") ? (
                  <div className="">
                    <h2 className="text-center title-dateRange strong">
                      Filtrer par:{" "}
                    </h2>
                    <div className="row">
                      <div className="alert alert-dark" role="alert">
                        2 - Choisissez ci-dessous le groupe de numéros pour
                        lequel vous souhaitez avoir les statistiques d'appels.
                        Si vous choisissez plusieurs numéros, leurs statistiques
                        seront donc agrégées
                      </div>
                      <div className="col-4 giveNote">
                        <h3 className="text-center">Note</h3>
                        <div>
                                
  <FormControlLabel control={<Checkbox    color="primary"   onChange={(e) => setStrict(e.target.checked)}                  checked={strict}
 />} label="Inférieur ou égale à" />
</div>
                        <RatingOption
                          readOnly={readOnly}
                          value={value}
                          ind={0}
                          setRating={setRating}
                        />
                      </div>
                      <div className="col-8 serviceNote">
                      <h3 className="text-center">Services</h3>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="Services"
                            name="Services"
                            value={serviceRanking}
                            onChange={handleChange}
                          >
                            {servicesArray.map((item: any, index: any) => (
                              <FormControlLabel
                                key={index}
                                value={item.label}
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      {serviceRanking && !props.data.loading && value? (
                        <div>
                          <div
                            className="alert alert-dark"
                            role="alert"
                            style={{ marginTop: "4em" }}
                          >
                            3 - Cliquez sur "Afficher" et patientez que les
                            statistiques soient calculés
                          </div>
                          <button
                            className="btn-grad"
                            onClick={() => getRankingResponse("/api/rating/avg/agent")}
                          >
                            Afficher
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    <h2 className="text-center title-dateRange">Numéro </h2>
                    <div className="alert alert-dark" role="alert">
                      2 - Choisissez ci-dessous le groupe de numéros pour lequel
                      vous souhaitez avoir les statistiques d'appels. Si vous
                      choisissez plusieurs numéros, leurs statistiques seront
                      donc agrégées
                    </div>

                    {numbersArray.map((item: any, index: any) => (
                      <label className="col-md-2" key={item.label + index}>
                        <input
                          readOnly
                          type="checkbox"
                          defaultChecked={Boolean(item.checked)}
                          onClick={() => toggle(index)}
                          onChange={() => onChangeCheck()}
                        />
                        <span
                          className="text-center"
                          style={{ fontSize: "16px", color: "black" }}
                        >
                          {" "}
                          {item.label}
                        </span>
                      </label>
                    ))}
                    {did &&
                    !window.location.href.includes("/analyse") &&
                    !props.data.loading ? (
                      <div>
                        <div
                          className="alert alert-dark"
                          role="alert"
                          style={{ marginTop: "4em" }}
                        >
                          3 - Cliquez sur "Afficher" et patientez que les
                          statistiques soient calculés
                        </div>
                        <button
                          className="btn-grad"
                          onClick={() => handleDid()}
                        >
                          Afficher
                        </button>
                      </div>
                    ) : window.location.href.includes("/analyse") &&
                      !props.data.loading ? (
                      <div>
                        <div
                          className="alert alert-dark"
                          role="alert"
                          style={{ marginTop: "4em" }}
                        >
                          3 - Cliquez sur "Afficher" et patientez que les
                          statistiques soient calculés
                        </div>
                        <button
                          className="btn-grad"
                          onClick={() => getDataAnalyse("/api/repporting")}
                        >
                          Afficher
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {props.data.error !== null ? (
          <h2 className="alert alert-danger" role="alert">
            Une Erreur est survenue
          </h2>
        ) : props.data.loading ? (
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-body ">
                      <Loading />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : props.data.dateRangeData || props.data.dataAnalyse || props.data.dataAgentRanking? (
          <ACDResult
            data={props.data.dateRangeData}
            dataAnalyse={props.data.dataAnalyse}
            dataAgentRanking = {props.data.dataAgentRanking}
          />
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    data: state.dateRange,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    PostDataRange: (url: string, dataToPost: any) =>
      dispatch(PostDataRange(url, dataToPost)),
    PostDataAnalyse: (url: string, dataToPost: any) =>
      dispatch(PostDataAnalyse(url, dataToPost)),
    PostDataAgentRanking: (url: string, dataToPost: any) =>
      dispatch(PostAgentRanking(url, dataToPost)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AcdDateRange);
