import { useEffect, useState } from 'react';
import { TableCell, TableContainer, TableHead, TableBody, TableRow, Table } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import Loader from './Loader';


export default function PersistentDrawerRight({date1, date2, did, setDids}:any) {
	const [datas, setDatas] = useState<any>([]);
    console.log("date1",date1);
    console.log("date2",date2);
    const [isLoading, setIsLoading] = useState<Boolean>(true);
	const fetchDataTable = async () => {
        console.log(did)
        const url = did ===""?`https://ntx-remanence-web.dt-srv-195.ucorp.io/global/remanencedaily/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}`:`https://ntx-remanence-web.dt-srv-195.ucorp.io/global/remanencedaily/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}/${did}`
		const response = await axios.get(url);
		let data = await response.data;
		setDatas(data);
		let s: any = [];
		for (let i = 0; i < data.length; i++) s = new Set ([...s, ...data[i].dids.split(", ")]);
		setDids(s);
        setIsLoading(false);
	}

	useEffect(() => {
        setDatas([]);
        setIsLoading(true);
        fetchDataTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[date2, did]);
	return (
		<>
			<section className="card-body col-md-10">
            <TableContainer style={{ height: 'calc(100vh - 50px)', overflowY: 'auto'}}>
                <Table>
                    <TableHead style={{ position: 'sticky', top: 0,zIndex: 100, backgroundColor: `rgba(255, 255, 255, 1)` }}>
                        <TableCell className='text-center'>JOUR</TableCell>
                        <TableCell className='text-center'>REMANENCE</TableCell>
                        <TableCell className='text-center'>NOMBRE D'APPELANTS UNIQUES</TableCell>
                        <TableCell className='text-center'>NOMBRE D'APPELS</TableCell>
                        <TableCell className='text-center'>PAR SEMAINE</TableCell>
                    </TableHead>
                    <TableBody>
                    {isLoading?<Loader/>:null}
                    {
                        datas?datas.slice().reverse().map((d: any, index: number) => (
                            <TableRow>
                                <TableCell className='text-center'><strong>{d.mois.split('-')[2]}</strong></TableCell>                                    <TableCell className='text-center'>{(parseFloat(d.calls)/parseFloat(d.caller_number)).toFixed(2)}</TableCell>
                                <TableCell className='text-center'>{(parseFloat(d.calls)/parseFloat(d.caller_number)).toFixed(2)}</TableCell>
                                <TableCell className='text-center'>{d.calls}</TableCell>
                                <TableCell className='text-center'>{d.calls}</TableCell>
                            </TableRow>
                        )):null
                    }
                    </TableBody>
                        
                </Table>
            </TableContainer>
        </section>
		</>
	);
}