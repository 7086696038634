import { createTheme, ThemeProvider } from "@material-ui/core";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
const muiTheme = createTheme({});

const Player = (props: any) => {
  return (
    <div className={`player`}>
      <ThemeProvider theme={muiTheme}>
        <AudioPlayer
          onPlay={(e) => console.log("onPlay")}
          src={`https://3cx-records.ucorp.io/nfs/${props.src}`}
          showDownloadProgress={true}
          showFilledVolume={true}
          showJumpControls={true}
        />
      </ThemeProvider>
    </div>
  );
};

export default Player;
