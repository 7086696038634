import {
  FETCH_PLANIFICATION_FAILURE,
  FETCH_PLANIFICATION_REQUEST,
  FETCH_PLANIFICATION_SUCCESS,
} from "./planificationType";

const initialState = {
  planificationData: [{ labels: [], datasets: [] }],
  error: null,
  loading: false,
};
const PlanificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PLANIFICATION_REQUEST:
      return { ...state, loading: true };
    case FETCH_PLANIFICATION_SUCCESS:
      return {
        planificationData: action.payload,
        error: null,
        loading: false,
      };
    case FETCH_PLANIFICATION_FAILURE:
      return {
        planificationData: [],
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default PlanificationReducer;
