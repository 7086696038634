/**
 * @author("tiana.randrianarivony@gmail.com")
 */
import {convertSecToH} from "../Components/librairies/dateFunction";

const options = {
plugins: {
    tooltip: {
        callbacks: {
            label: (context: {dataset: {type: string, label : string}, formattedValue: string}) => {
                const value = parseFloat(context.formattedValue.replace(",","."));
                return `${context.dataset.label}: ${context.dataset.type === 'line' ? convertSecToH(3600* value) : value}`
            }
        }
    }},
    scales: {
        y : {
            title: {
                display: true,
                text: 'Durée (heure)'
            },
            ticks: {
                // Include a dollar sign in the ticks
                callback: function(value: any, index: any, values: any) {
                    return `${convertSecToH(value*3600)}`;
                }
            }
        },
        calls_y_axis: {
            position: 'right',
            title: {
                text: 'Nombre d\'appels',
                display: true
            }
        }
    },
};
const chartPatternData = {
    datasets: [
        {
            type: 'line',
            label: 'TMC',
            borderColor: '#438ca2',
            borderWidth: 2,
            fill: true,
            data: [{}]
        },
        {
            type: 'line',
            label: 'DURATION',
            borderColor: '#3b36d2',
            borderWidth: 2,
            fill: true,
            data: [{}]
        },
        {
            type: 'bar',
            label: 'APPELS',
            backgroundColor: '#a2438d',
            data: [{}],
            borderColor: 'white',
            borderWidth: 2,
            yAxisID: "calls_y_axis",
        },

    ],
}

const optionsComparatifDetail = {
    spanGaps: true,
    plugins: {
      tooltip: {
        callback: function (value: any, index: any, values: any) {
          return `${convertSecToH(value)} [${value}secs]`;
        }
      }
    },
    scales: {
      calls_y_axis: {
        position: 'left',
        title: {
          display: true,
          text: 'Nombre d\'appels'
        }
      }
    },
  };
const optionsPlanification = {
    responsive: true,
    plugins: {
        title: {
            display: true,
        },
        tooltip: {
            mode: 'index'
        },
    },
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Day'
            }
        },
        y: {
            stacked: true,
            title: {
                display: true,
                text: 'Value'
            }
        }
    }

}
export {
    options as ChartOptions,
    chartPatternData,
    optionsComparatifDetail,
    optionsPlanification
}