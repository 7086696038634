import { FC, ReactElement } from "react";
import React from "react";
import { Switch, Route } from 'react-router-dom';
import ACDRequalifResult from "./ACDRequalifResult";
import ACDAffluenceResult from "./ACDAffluenceResult";
const ACDResultAffluence: FC<{ data: any, title: Array<string>, page: number, did: string, toCSV: any, prec: any, suiv: any, selectOrder: string, groupeByDetail: string, groupBy: any }> = ({ data, title, page, did, toCSV, prec, suiv, selectOrder, groupeByDetail, groupBy }): ReactElement => {
    return <Switch>
        <Route path="/acd-dids-affluence" component={() => <ACDAffluenceResult items={data} titles={title} page={page} did={did} toCSV={toCSV} prec={prec} suiv={suiv} selectOrder={selectOrder} groupeByDetail={groupeByDetail} groupBy={groupBy} />} />
        <Route path="/acd-requalif" component={() => <ACDRequalifResult datas={data} />} />
    </Switch>
}
export default React.memo(ACDResultAffluence);