/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import Datetime from "react-datetime";
import moment from "moment";
import { TableCell, TableContainer, TableHead, TableBody, TableRow } from '@material-ui/core';
import "react-datetime/css/react-datetime.css";
import { Table } from 'react-bootstrap';
import Loader from './Loader';
import TableFooter from '@mui/material/TableFooter';
import { format } from 'date-fns';
import axios from 'axios';
import { generateUniqueKey } from '../../Tools/utility';


export default function PersistentDrawerRight() {
	const [date1, setDate1] = useState<any>(new Date());
	const [date2, setDate2] = useState<any>(new Date());
	const [selectedButton, setSelectedButton] = useState<any>([]);
	const [remDatas, setremDatas] = useState<any>([]);
	const [showedData, setShowedData] = useState<any>([]);
	const [newRemData, setNewRemData] = useState<any>([]);
	const [dids, setDids] = useState<any>(null);
	const [nbRow, setNbRow] = useState<any>(100);
	const [page, setPage] = useState<number>(1);
	const [curPage, setCurPage] = useState<number>(1);
	const [clientNumber, setClientNumber] = useState<Array<string>>([]);
	const [recLists, setRecLists] = useState<any>([]);
	const [isDescStart, setIsDescStart] = useState<boolean>(true);
	const [hoverStyle, setHoverStyle] = useState<String>("none");
	const [nbHoverStyle, setNbHoverStyle] = useState<String>("none");
	const [isDescNbStart, setIsDescNbStart] = useState<boolean>(true);
	const [acdHoverStyle, setAcdHoverStyle] = useState<String>("none");
	const [isDescAcdStart, setIsDescAcdStart] = useState<boolean>(true);

	const isValidDate1 = (date: Date | null) => {
		if (!date2) return true;
		return date !== null && date <= new Date();
	}
	const isValidDate2 = (date: Date | null) => {
		if (!date1) return true;
		return date !== null && date >= date1 && date <= new Date();
	}
	const downloadCsv = () => {
		const headers = [
			"APPELANTS UNIQUES", "PREMIER APPEL", "DERNIER APPEL","Nombre d'appels",
			"ACD", "HEURE TOTALE D'APPEL", "STATUS"];
		const csvContent = 
			"data:text/csv;charset=utf-8," +
			headers.join(',') + 
			showedData.map( (item: any) => 	
				`\n${item.from_userpart}, ${item.first_call}, ${item.last_call}, ${item.calls},${item.acd}, ${item.sum}`);
			
			const encodedUri = encodeURI(csvContent);
			const link = document.createElement("a");
			link.setAttribute("href", encodedUri);
			link.setAttribute("download", `Report_Recurrence ${format(new Date(), 'HHmmss-ddMMyyyy')}.csv`);
			document.body.appendChild(link);
			link.click();
		};
	useEffect(() => {
		setShowedData(null);
		const fetchData = async () => {
			try{
				const response = await axios.get(`https://ntx-remanence-web.dt-srv-195.ucorp.io/recurrence/${moment(date1).format("YYYY-MM-DD")}/${moment(date2).format("YYYY-MM-DD")}`);
				setremDatas(response.data);
				setShowedData(response.data.slice(0, 25));
				setNewRemData(response.data);
				setDids(new Set(response.data.map((d: any) => (d.from_displayname.match(/\.([^:]+):/) || ['', ''])[1])));
				setNbRow(25);
				setClientNumber(response.data.map((d: any) => d.from_userpart));
				setRecLists(new Set(response.data.map((d: any) => d.calls)));
			}
			catch (error) {
				console.error('Erreur lors du chargement des données:', error);
			}
		}
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date2])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handlePageOnShowedData=()=>{
		setShowedData(newRemData.slice((curPage - 1) * nbRow, curPage * nbRow));
	}
	useEffect(() => {
		if(newRemData) handlePageOnShowedData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, curPage]);

	return <>
		<div className="container-fluid blck-comparatif">
				<h3 className="text-center title">RÉCURRENCE Client {isNaN(Date.parse(date1)) ? date1 : moment(date1).format("DD/MM/YYYY")} au {isNaN(Date.parse(date2)) ? date2 : moment(date2).format("DD/MM/YYYY")}</h3>
			</div>
			<div className='card'>
				<section className="card-body">
					<p className='row text-center'>
						Periode
						<Datetime
							dateFormat="DD/MM/YYYY"
							onChange={setDate1}
							locale="fr-ca"
							timeFormat={false}
							className='col-md-2'
							isValidDate={isValidDate1}
						/> 
						au <Datetime
							dateFormat="DD/MM/YYYY"
							onChange={setDate2}
							locale="fr-ca"
							timeFormat={false}
							isValidDate={isValidDate2}
							className='col-md-2'
						/>
						<select
							placeholder='Chercher des numeros'
							onChange={(e) => {
								setShowedData( e.target.value === '' ? newRemData :
									remDatas.filter((data:any)=>data.from_userpart === e.target.value
								))
							}}
							className='col-md-3'
						>
							<option value="">Afficher tous</option>
							{
								clientNumber.map((item: any, index: number) => (
									<option key={generateUniqueKey()} value={item}>{item}</option>
								))
							}
						</select>
						<select 
							name="recurrence_filter" 
							id="recurrence_filter"
							onChange={(e) => {
							}}
							className='col-md-3'
						>
							<option value="">Tous les appels</option>
							{
								recLists ? Array.from(recLists).map((item: any, index: number) => (
									<option key={generateUniqueKey()} className="col-md-12" value={item}>{item}</option>
								)):null
							}
						</select>
					</p>
				</section>
			</div>
			<div className='card' style={{  position: '-webkit-sticky', top: 0, zIndex: 100 }}>
				<div className='row'  style={{position: 'sticky', bottom: 0, backgroundColor: `rgba(255, 255, 255, 1)`}}>
					<section className="card-body col-md-2" 
						style={{ height: 'calc(100vh - 50px)', overflowY: 'auto'}}
					>
						<h3 className='text-center'>Dating</h3>
						<button className="btn-grad col-md-12"onClick={()=>{
							setShowedData(remDatas)
							setNewRemData(remDatas)
							setSelectedButton(1000);
						}}
						style={{backgroundColor: selectedButton === 1000 ? 'black' : ''}}
						>TOUT</button>
						{
							dids ? Array.from(dids).map((item: any, index: number) => (
								<button key={generateUniqueKey()} className="btn-grad col-md-12" 
								onClick={()=>{
										setNewRemData(remDatas.filter((data:any)=>(data.from_displayname.match(/\.([^:]+):/) || ['', ''])[1] === item));
										setShowedData(remDatas.filter((data:any)=>(data.from_displayname.match(/\.([^:]+):/) || ['', ''])[1] === item));
										setSelectedButton(item);
									}}
								style={{backgroundColor: item === selectedButton ? 'black' : ''}}
								>{item}</button>
							)):null
						}
					</section>
					<section className="card-body col-md-10">
						<TableContainer className='' style={{ height: 'calc(100vh - 50px)', overflowY: 'auto'}}>
							<Table>
								<TableHead style={{ position: 'sticky', top: 0,zIndex: 100, backgroundColor: `rgba(255, 255, 255, 1)` }}>
									<TableCell className='text-center'>DID</TableCell>
									<TableCell className='text-center'>APPELANTS UNIQUES</TableCell>
									<TableCell className='text-center'style={{ cursor: 'pointer', backgroundColor: `${hoverStyle}`}}
										onClick={()=>{
											let sort = showedData;
											if (sort) {
												sort = isDescStart? sort.sort((a: any, b: any) => 
													(new Date(a.first_call).getTime() - new Date(b.first_call).getTime())):
													sort.sort((a: any, b: any) => 
													new Date(b.first_call).getTime() - (new Date(a.first_call).getTime()));
											}
											setIsDescStart(!isDescStart);
											setShowedData(sort);
										}}
										onMouseEnter={() => setHoverStyle("Silver")}
										onMouseLeave={() => setHoverStyle("White")}
									>PREMIER APPEL</TableCell>
									<TableCell className='text-center'>DERNIER APPEL</TableCell>
									<TableCell className='text-center' 
										style={{ cursor: 'pointer', backgroundColor: `${nbHoverStyle}`}}
										onClick={()=>{
											let sort = showedData;
											if (sort) {
												sort = isDescNbStart? sort.sort((a: any, b: any) => a.calls - b.calls):
													sort.sort((a: any, b: any) => b.calls - a.calls);
											}
											setIsDescNbStart(!isDescNbStart);
											setShowedData(sort);
											console.log(sort)
										}}
										onMouseEnter={() => setNbHoverStyle("Silver")}
										onMouseLeave={() => setNbHoverStyle("White")}
									>NOMBRE D'APPELS </TableCell>
									<TableCell className='text-center'
										style={{ cursor: 'pointer', backgroundColor: `${acdHoverStyle}`}}
										onClick={()=>{
											let sort = showedData;
											if (sort) {
												sort = isDescAcdStart? sort.sort((a: any, b: any) => 
													new Date(`1970-01-01T${a.acd}`).getTime() - new Date(`1970-01-01T${b.acd}`).getTime()
												):
													sort.sort((a: any, b: any) => new Date(`1970-01-01T${a.acd}`).getTime() + new Date(`1970-01-01T${b.acd}`).getTime());
											}
											setIsDescAcdStart(!isDescAcdStart);
											setShowedData(sort);
										}}
										onMouseEnter={() => setAcdHoverStyle("Silver")}
										onMouseLeave={() => setAcdHoverStyle("White")}
									>ACD</TableCell>
									<TableCell className='text-center'>HEURE TOTALE D'APPEL</TableCell>
									<TableCell className='text-center'>STATUS</TableCell>
								</TableHead>
								<TableBody>
									{
										!showedData ? <Loader /> : 
										showedData.map((item: any) => (
											<TableRow key={generateUniqueKey()}>
												<TableCell className='text-center'>{item.did.split('.')[1]}</TableCell>
												<TableCell className='text-center'>{item.from_userpart}</TableCell>
												<TableCell className='text-center'>{item.first_call}</TableCell>
												<TableCell className='text-center'>{item.last_call}</TableCell>
												<TableCell className='text-center'>{item.calls}</TableCell>
												<TableCell className='text-center'>{item.acd}</TableCell>
												<TableCell className='text-center'>{item.sum}</TableCell>
												<TableCell className='text-center'>{item.status}</TableCell>
											</TableRow>)
										)
									}
								</TableBody>
								<TableFooter style={{position: 'sticky', bottom: 0, backgroundColor: `rgba(255, 255, 255, 1)`}}>
									<TableRow>
										<TableCell className='text-center'>{showedData?showedData.length:0}/{newRemData?newRemData.length:0} Lignes</TableCell>
										<TableCell className='text-center'>
											<select id="nbRow" name="nb_row" className='col-md-12' onChange={(row)=>{
													const val = parseInt(row.target.value, 10);
													if(!isNaN(val)){
														let numRow = val;
														numRow = Math.floor(newRemData.length/numRow);
														numRow = (newRemData.length%numRow<=0 && (newRemData.length/numRow<=1))? numRow : numRow+1;
														setNbRow(val);
														setPage(numRow)
													}
												}}>
												<option value="25">25</option>
												<option value="50">50</option>
												<option value="100">100</option>
												<option value={newRemData?newRemData.length:1}>{newRemData?newRemData.length:1}</option>
											</select>
										</TableCell>
										<TableCell className='text-center' colSpan={3}>
											<button className='col-md-2 btn-grad' onClick={()=>{if(curPage<page)setCurPage(curPage+1)}}>&gt; </button>
											<button className='col-md-4 btn-grad' onClick={()=>{}}>{curPage}/{page}</button>
											<button className='col-md-2 btn-grad' onClick={()=>{if(curPage>1)setCurPage(curPage-1)}}>&lt; </button>
										</TableCell>
										<TableCell className='text-center'> <button className="btn-grad col-md-12" onClick={()=>{downloadCsv()}}>Telecharger CSV</button></TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</section>
				</div>
			</div>
	</>
}