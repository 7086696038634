/* eslint-disable react-hooks/exhaustive-deps */
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import Datetime from "react-datetime";
import Loader from "../Recurrence/Loader";
import { formatGraphData, getAllServices, getCallsAnalysisData, transformTableData } from "./CallAnalysisService";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CallAnalysisGraph from "./CallAnalisisGraph";

const CallAnalysis:React.FC = ()=>{
	const [date1, setDate1] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
	const [date2, setDate2] = useState<any>(new Date());
    const [tableauData, setTableauData] = useState<any>([]);
    const [GraphData, setGraphData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showGraph, setShowGraph] = useState<boolean>(true);
    const [showTableau, setShowTableauData] = useState<boolean>(true);
    const [listServices, setListServices] = useState<Set<string>>(new Set());
    const [datas, setDatas] = useState<any>([]);
    const [titre, setTitre] = useState<string>("Analyse sur tous les services")

    const isValidDate1 = (date: Date | null) => {
		if (!date2) return true;
		return date !== null && date <= new Date();
	}
	const isValidDate2 = (date: Date | null) => {
		if (!date1) return true;
		return date !== null && date >= date1 && date <= new Date();
	}

    useEffect(() => {
        setLoading(true); 
        const fetchData = async () => {
            const dt = await getCallsAnalysisData(date1, date2);
            setDatas(dt)
            const tableData = transformTableData(dt);
            setTableauData(tableData);
            setListServices(getAllServices(dt))
            setLoading(false);
            setGraphData(formatGraphData(tableData));
        }
        fetchData();            
    },[date2])
    return <>
        <div className="card">
            <div className="card-body">
                <h3 className="text-center title">{titre}</h3>
                <div className="row ">
                        Date1 <Datetime
							dateFormat="DD/MM/YYYY"
							onChange={(d) => d instanceof Date && setDate1(d)}
							locale="fr-ca"
							timeFormat={false}
							className='col-md-2'
							isValidDate={isValidDate1}
						/> Date 2
                        <Datetime
							dateFormat="DD/MM/YYYY"
							onChange={(d) =>d instanceof Date && setDate2(d)}
							locale="fr-ca"
							timeFormat={false}
							isValidDate={isValidDate2}
							className='col-md-2'
						/>
                        <select className="col-md-4" name="services" id="services" onChange={event=>{
                            const filterdData = datas.filter((x:any) => x.service === event.target.value);
                            const transformedFilter = transformTableData(filterdData);
                            setTableauData(transformedFilter);
                            setGraphData(formatGraphData(transformedFilter));
                            setTitre(`Analyse sur ${event.target.value}`)
                        }}>
                            <option key={"null"} value="">Selectionez un service</option>
                            {
                                [...listServices].map((service:string) => 
                                <option key={service} value={service}>{service}</option>)
                            }
                        </select>
                </div>
            </div>
        </div>
        <div className="card">
            <section className="card-body col-md-12">
                <IconButton className="btn btn-primary col-md-12" onClick={() => setShowGraph(!showGraph)}>
                    {!showGraph?<VisibilityOffIcon/>:<VisibilityIcon/>}
                    Appels perdus du {date1.toLocaleDateString()} au {date2.toLocaleDateString()} par status
                </IconButton>
                <p style={{color:"white"}}>----</p>
                {
                    loading?<Loader/>:showGraph? <CallAnalysisGraph data={GraphData}/>: null
                }
            </section>
        </div>
        <div className='card' style={{  position: '-webkit-sticky', top: 0, zIndex: 100 }}>
            <div className='row'  style={{position: 'sticky', bottom: 0, backgroundColor: `rgba(255, 255, 255, 1)`}}>
                <section className="card-body col-md-12">
                    <IconButton className="btn btn-primary col-md-12" onClick={() => setShowTableauData(!showTableau)}>
                        {!showTableau?<VisibilityOffIcon/>:<VisibilityIcon/>}
                        {titre} du {date1.toLocaleDateString()} au {date2.toLocaleDateString()}
                    </IconButton>
                    {showTableau?<TableContainer className='' style={{ height: 'calc(100vh - 50px)', overflowY: 'auto'}}>
                        <Table>
                            <TableHead style={{ position: 'sticky', top: 0,zIndex: 100, backgroundColor: `rgba(255, 255, 255, 1)` }}>
                                <TableCell className='text-center'>Jours</TableCell>
                                <TableCell className='text-center'>Answered</TableCell>
                                <TableCell className='text-center'>Call failed</TableCell>
                                <TableCell className='text-center'>Cancelled</TableCell>
                                <TableCell className='text-center'>Poll expired</TableCell>
                                <TableCell className='text-center'>User requested</TableCell>
                                <TableCell className='text-center'>Caller has dropped call</TableCell>
                                <TableCell className='text-center'>Transfer OK</TableCell>
                                <TableCell className='text-center'>%cancelled</TableCell>
                            </TableHead>
                            <TableBody>
                                {                                    
                                    loading?<Loader/>: Object.keys(tableauData).map((key)=>{
                                        const canceled = (+tableauData[key].hasOwnProperty("Cancelled")?tableauData[key]["Cancelled"].length:0 );
                                        const percent = +tableauData[key].hasOwnProperty("Answered")?tableauData[key]["Answered"].length:0 +
                                        (+tableauData[key].hasOwnProperty("Answered")?tableauData[key]["Answered"].length:0) + 
                                        (+tableauData[key].hasOwnProperty("Call failed")?tableauData[key]["Call failed"].length:0 )+
                                        (+tableauData[key].hasOwnProperty("Cancelled")?tableauData[key]["Cancelled"].length:0 )+
                                        (+tableauData[key].hasOwnProperty("Poll expired")?tableauData[key]["Poll expired"].length:0 )+
                                        (+tableauData[key].hasOwnProperty("User requested")?tableauData[key]["User requested"].length:0 )+
                                        (+tableauData[key].hasOwnProperty("Caller has dropped call")?tableauData[key]["Caller has dropped call"].length:0 )+
                                        (+tableauData[key].hasOwnProperty("Transfer OK")?tableauData[key]["Transfer OK"].length:0) 
                                        ;
                                        return +key!==0?<TableRow>
                                            <TableCell className='text-center'>{key}</TableCell>
                                            <TableCell className='text-center'>{tableauData[key].hasOwnProperty("Answered")?tableauData[key]["Answered"].length:0}</TableCell>
                                            <TableCell className='text-center'>{tableauData[key].hasOwnProperty("Call failed")?tableauData[key]["Call failed"].length:0}</TableCell>
                                            <TableCell className='text-center'>{tableauData[key].hasOwnProperty("Cancelled")?tableauData[key]["Cancelled"].length:0}</TableCell>
                                            <TableCell className='text-center'>{tableauData[key].hasOwnProperty("Poll expired")?tableauData[key]["Poll expired"].length:0}</TableCell>
                                            <TableCell className='text-center'>{tableauData[key].hasOwnProperty("User requested")?tableauData[key]["User requested"].length:0}</TableCell>
                                            <TableCell className='text-center'>{tableauData[key].hasOwnProperty("Caller has dropped call")?tableauData[key]["Caller has dropped call"].length:0}</TableCell>
                                            <TableCell className='text-center'>{tableauData[key].hasOwnProperty("Transfer OK")?tableauData[key]["Transfer OK"].length:0}</TableCell>
                                            <TableCell className='text-center'>{(canceled*100/percent).toFixed(2)}%</TableCell>
                                        </TableRow>:null
                                    })
                                }
                            </TableBody>
                            <TableFooter style={{position: 'sticky', bottom: 0, backgroundColor: `rgba(255, 255, 255, 1)`}}>
                                
                            </TableFooter>
                        </Table>
                    </TableContainer>:null}
                </section>
            </div>
        </div>
        
    </>
}

export default CallAnalysis;
