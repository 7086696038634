 import {FC, ReactElement} from "react";

 
 const ACDRequalifResult : FC<{datas: object}> = ({ datas}) : ReactElement => {

     return   (
         <h1>eeeeee</h1>
     )
 }
 
 export default ACDRequalifResult;