/**
 * @author("tiana.randrianarivony@gmail.com")
 */

import {FC, ReactElement, useEffect, useState} from "react";
import {convertSecToH} from "../../librairies/dateFunction";

type Metadata = {
    average_duration_int: string,
    average_duration_time: string,
    call_number: string,
    overall_duration_int: string,
    overall_duration_time: string
}
type dataItem = {
    code: number,
    message: string|null,
    metadata : Metadata,
    data: any[]
}
const ACDResultTab : FC<{datas: object}> = ({ datas}) : ReactElement => {
    const [totalAppel, setTotalAppel] = useState<number>(0)
    const [totalTMC, setTotalTMC ] = useState<number>(0)
    const [heuresG, setTotalHours] = useState<number>(0)
    let _totalAppel = 0;
    let _totalTMC = 0;
    let _totalHours = 0;
    const [items, ] = useState<any>(Object.keys(datas).map((key) => {
        // @ts-ignore
        const d : dataItem = datas[key];
        _totalAppel += +d.metadata.call_number
        _totalTMC += +d.metadata.average_duration_int
        _totalHours += +d.metadata.overall_duration_int
        return [
            String(key),
            // @ts-ignore
            datas[key],
        ]
    }))
    useEffect(() => {
        setTotalAppel(_totalAppel)
        setTotalTMC(_totalTMC)
        setTotalHours(_totalHours)
    }, [_totalTMC, _totalAppel, _totalHours])

    return   <div className="card">
            <div className="card-body">
            <h2 className="text-center">Résultat</h2>
            {items.length > 0 ? (
                <div className={items.length > 8 ? "table" : ''}>
                <table className="table table-striped able-bordered">
                    <thead>
                    <tr>
                        <th></th>
                        <th>APPELS</th>
                        <th>TMC</th>
                        <th>HEURES GENEREES</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((el: any, index: any) => {
                        return (
                            <tr key={index + "k"}>
                                <td>{el[0]}</td>
                                <td>{el[1].metadata.call_number}</td>
                                <td>{el[1].metadata.average_duration_time} </td>
                                <td>{el[1].metadata.overall_duration_time}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td>TOTAL</td>
                        <td>{totalAppel} </td>
                        <td>{convertSecToH(totalTMC)} </td>
                        <td>{convertSecToH(heuresG)} </td>
                    </tr>
                    </tbody>
                </table>
                </div>
        ) : (
            <h2 className="text-center">Aucun Résultat</h2>
        )}
    </div>
    </div>
}

export default ACDResultTab;