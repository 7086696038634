import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #252831;
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #2974ad;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  &:hover,
  &:focus{
    background: #000000;
    cursor: pointer;
    font-weight: bold;
    color: #f5f5f5;
  }
  &.active {
    border-left: 5px solid #ffffff;
    border-right: 5px solid #ffffff;
    background: #040a58;
    cursor: pointer;
    font-weight: bold;
    color: #ffffff;
    padding-left: 1.5em;
  }
`;

const SubMenu = (props: any) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink
        to={props.item.path}
        onClick={props.item.subNav && showSubnav}
      >
        <div>
          {props.item.icon}
          <SidebarLabel>{props.item.title}</SidebarLabel>
        </div>
        <div>
          {props.item.subNav && subnav
            ? props.item.iconOpened
            : props.item.subNav
            ? props.item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        props.item.subNav.map((item: any, index: number) => {
          return (
            <DropdownLink
              className={`${
                window.location.pathname === item.path && "active"}`}
              to={item.path}
              key={index}
            >
              {item.icon}
              <SidebarLabel>{item.title} </SidebarLabel>
            </DropdownLink>
          )
        })}
    </>
  );
};
export default SubMenu;
