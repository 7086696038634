import React from "react";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as IoIcons5 from "react-icons/io5";
export const SidebarDataAdmin = [
  {
    title: "UCORP",
    path: "/acd-dids-daterange",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Analyse",
        path: "/analyse",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Tableau TMC selon Date",
        path: "/acd-dids-daterange",
        icon: <IoIcons5.IoCalendarSharp />,
      },
      {
        title: "Graphe Evolution TMC",
        path: "/acd-graph",
        icon: <IoIcons5.IoBarChartSharp />,
      },
      {
        title: "Affluence",
        path: "/acd-dids-affluence",
        icon: <IoIcons5.IoList />,
      },
      {
        title: "Chercher un appel",
        path: "/acd-dids-agent",
        icon: <IoIcons5.IoList />,
      },
      {
        title: "Rating agent",
        path: "/agent-ranking",
        icon: <IoIcons5.IoList />,
      },
      {
        title: "Planification",
        path: "/planification",
        icon: <IoIcons5.IoReorderFourSharp />,
      },
      {
        title: "Temps de conn.",
        path: "/connected-time",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Ajout catégories",
        path: "/add-categories",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Requalification",
        path: "/requalif",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Admin Annuaires",
        path: "/admin-annuaires",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Notifier Agent",
        path: "/notify-agent",
        icon: <IoIcons5.IoNotifications />,
      },
      {
        title: "Derniers appels",
        path: "/last-calls",
        icon: <IoIcons5.IoBarChart />,
      },
      /*{
        title: "Call Stats",
        path: "/call-stats",
        icon: <IoIcons5.IoBarChart />,
      },*/
      {
        title: "Appels courts",
        path: "/appels-courts",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Récurrence",
        path: "/recurrence",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Rémanence",
        path: "/remanence",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Analyse des appels",
        path: "/callsanalysis",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Analyse Vetos",
        path: "/vetos",
        icon: <IoIcons5.IoBarChart />,
      }
    ],
  },
  {
    title: "TELON",
    path: "/acd-dids-daterange",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Comparatif",
        path: "/comparatif",
        icon: <IoIcons5.IoGitCompare />,
      },
      {
        title: "Hardware",
        path: "/hardware",
        icon: <IoIcons5.IoHardwareChipOutline />,
      },
      {
        title: "Analyse des logs",
        path: "/analyse-logs",
        icon: <IoIcons5.IoLogoBuffer />,
      },
    ],
  },
];
export const SidebarData = [
  {
    title: "UCORP",
    path: "/acd-dids-daterange",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "TMC selon Date",
        path: "/acd-dids-daterange",
        icon: <IoIcons5.IoCalendarSharp />,
      },
      {
        title: "Graphe Evolution TMC",
        path: "/acd-graph",
        icon: <IoIcons5.IoBarChartSharp />,
      },
      {
        title: "Affluence",
        path: "/acd-dids-affluence",
        icon: <IoIcons5.IoList />,
      },
      {
        title: "Chercher un appel",
        path: "/acd-dids-agent",
        icon: <IoIcons5.IoList />,
      },
      {
        title: "Comparatif",
        path: "/comparatif",
        icon: <IoIcons5.IoGitCompare />,
      },
      {
        title: "Planification",
        path: "/planification",
        icon: <IoIcons5.IoReorderFourSharp />,
      },
      {
        title: "Temps de conn.",
        path: "/connected-time",
        icon: <IoIcons5.IoBarChart />,
      },
    ],
  },
];
export const SideBarSuperviseur = [
  {
    title: "UCORP",
    path: "/requalif",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Requalification",
        path: "/requalif",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Chercher un appel",
        path: "/acd-dids-agent",
        icon: <IoIcons5.IoList />,
      },
      {
        title: "Rating agent",
        path: "/agent-ranking",
        icon: <IoIcons5.IoList />,
      },/*
      {
        title: "Call Stats",
        path: "/call-stats",
        icon: <IoIcons5.IoBarChart />,
      },*/
      {
        title: "Récurrence",
        path: "/recurrence",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Rémanence",
        path: "/remanence",
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: "Analyse des appels",
        path: "/callsanalysis",
        icon: <IoIcons5.IoBarChart />,
      }
    ],
  },
];

export const SideBarVeto = [
  {
    title: "VETO",
    path: "/vetos",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Analyse Vetos",
        path: "/vetos",
        icon: <IoIcons5.IoBarChart />,
      }
    ],
  },
]