import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "./Components/sidebar/sidebar";
import { Provider } from "react-redux";
import "./App.scss";
import SignIn from "./Components/login/signIn";
import AcdDateRange from "./Components/ACD/acdDateRange";
import Affluence from "./Components/ACD/acdAffluence";
import Agent from "./Components/ACD/acdAgent";
import Comparatif from "./Components/Comparatif/Comparatif";
import Planification from "./Components/Planification/Planification";
import ConnectedTime from "./Components/ConnectedTime/ConnectedTime";
// import DidServiceClient from './Components/ACD/didServiceClient';
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "./Components/navbar/navbar";
import useToken from "./Components/librairies/userToken";
import jwt_decode from "jwt-decode";
import { FC, ReactElement, useEffect } from "react";
import Hardware from "./Components/hardware/hardware";
import AnalyseLogs from "./Components/Analyse/AnalyseLogs";
import AddCategories from "./Components/Categories/addCategories";
import Requalif from "./Components/requalification/requalif";
import TablesMarquesLeads from "./Components/admin_annuaires/TablesMarquesLeads";
import store from "./store/store";
import React from "react";
import Notify from "./Components/Notify/Notify";
import LastCalls from "./Components/LastCalls/LastCalls";
//import DidStats from "./Components/DidStats/Didstats";
import CallStats from "./Components/CallStats/Callstats";
import AppelsCourts from "./Components/AppelsCourts/appels-courts";
import Recurrence from "./Components/Recurrence/Recurrence";
import Remanence from "./Components/Remanence/Remanence";
import CallAnalysis from "./Components/CallAnalisis/CallAnalysisComponent";
import StatsVetos from "./Components/Veto/StatsVetos";

export const TokenContext = React.createContext("");

const App: FC = (): ReactElement => {
  const { token, setToken } = useToken();
  const expiration: any = token ? jwt_decode(token) : null;
  const useInfo: any = token ? jwt_decode(token) : null;
  let checkInterval: any = null;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    checkInterval = expiration
      ? setInterval(() => {
        if (
          expiration != null &&
            expiration.exp * 1000 < new Date().getTime()
        ) {
          localStorage.clear();
          window.location.reload();
        }
      }, 3000)
      : clearInterval(checkInterval);
  }, [expiration]);

  if (!token) {
    return <SignIn setToken={setToken} />;
  }
  return (
    <Provider store={store}>
      <TokenContext.Provider value={token}>
      <div className="wrapper">
      <Router>
      <Sidebar />
      <div className="main-panel">
      <Navbar setToken={setToken} />
      {useInfo.roles[0] === "CLIENT_DAVID"? <Switch>
          <Route path="/vetos" component={() => <StatsVetos />} />
          <Redirect from="*" to={"/vetos"} />
      </Switch>:useInfo.roles[0] === "ROLE_ADMIN" ? (
        <Switch>
          <Route path="/acd-dids-daterange" component={() => <AcdDateRange />} />
          <Route path="/acd-graph" component={() => <AcdDateRange />} />
          <Route path="/acd-dids-affluence" component={() => <Affluence />} />
          <Route path="/acd-dids-agent" component={() => <Agent />} />
          <Route path="/agent-ranking" component={() => <AcdDateRange />} />
          <Route path="/comparatif" component={() => <Comparatif />} />
          <Route path="/planification" component={() => <Planification />} />
          <Route path="/connected-time" component={() => <ConnectedTime />} />
          <Route path="/acd-requalif" component={() => <Affluence />} />
          <Route path="/hardware" component={() => <Hardware />} />
          <Route path="/analyse" component={() => <AcdDateRange />} />
          <Route path="/analyse-logs" component={() => <AnalyseLogs />} />
          <Route path="/admin-annuaires" component={() => <TablesMarquesLeads />} />
          <Route path="/add-categories" component={() => <AddCategories />} />
          <Route path="/notify-agent" component={() => <Notify />} />
          {/* <Route path="/did-stats" component={() => <DidStats />} />*/}
          <Route path="/call-stats" component={() => <CallStats />} />
          <Route path="/requalif" component={() => <Requalif />} />
          <Route path="/last-calls" component={() => <LastCalls />} />
          <Route path="/appels-courts" component={() => <AppelsCourts />} />
          <Route path="/recurrence" component={() => <Recurrence />} />
          <Route path="/remanence" component={() => <Remanence />} />
          <Route path="/callsanalysis" component={() => <CallAnalysis />} />
          <Route path="/vetos" component={() => <StatsVetos />} />
          {/* <Route path="/did-service-client" component={() => <DidServiceClient token={token} />} /> */}
          <Redirect from="*" to={"/acd-dids-daterange"} />
          </Switch>
      ) : useInfo.username.replace("-", "") === "TELON" ? (
        <Switch>
          <Route path="/hardware" component={() => <Hardware />} />
          <Route path="/comparatif" component={() => <Comparatif />} />
          <Route path="/analyse-logs" component={() => <AnalyseLogs />} />
          <Redirect from="*" to={"/comparatif"} />
          </Switch>
      ) : useInfo.roles.includes("ROLE_SUPERVISEUR") ? (
        <Switch>
          <Route path="/requalif" component={() => <Requalif />} />
          <Route path="/acd-dids-agent" component={() => <Agent />} />
          <Route path="/agent-ranking" component={() => <AcdDateRange />} />
          <Route path="/call-stats" component={() => <CallStats />} />
          <Route path="/recurrence" component={() => <Recurrence />} />
          <Route path="/remanence" component={() => <Remanence />} />
          <Route path="/callsanalysis" component={() => <CallAnalysis />} />
          {/*<Redirect from="*" to={"/requalif"} />*/}
          </Switch>
      ) : (
        <Switch>
          <Route path="/acd-dids-daterange" component={() => <AcdDateRange />} />
          <Route path="/acd-graph" component={() => <AcdDateRange />} />
          <Route path="/acd-dids-affluence" component={() => <Affluence />} />
          <Route path="/notify-agent" component={() => <Notify />} />
          <Route path="/acd-dids-agent" component={() => <Agent />} />
          {/* <Route path="/did-service-client" component={() => <DidServiceClient token={token} />} /> */}
          <Redirect from="*" to={"/acd-dids-daterange"} />
          {/*<Route path="/did-stats" component={() => <DidStats />} />*/}
          <Route path="/call-stats" component={() => <CallStats />} />
          <Route path="/appels-courts" component={() => <AppelsCourts />} />
          <Route path="/recurrence" component={() => <Recurrence />} />
          <Route path="/remanence" component={() => <Remanence />} />
          <Route path="/vetos" component={() => <StatsVetos />} />
          </Switch>
      )}
      </div>
      </Router>
      </div>
      </TokenContext.Provider>
      </Provider>
  );
};

export default App;
