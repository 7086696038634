import React, {useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import styled from "styled-components";
import { SidebarData, SidebarDataAdmin, SideBarSuperviseur, SideBarVeto } from "./sideBarData";
import SubMenu from "./subMenu";
import { TokenContext } from "../../App";

const SidebarWrap = styled.div`
  width: 100%;
`;
const SidebarNav = styled.nav`
display: flex;
justify-content: center;
top: 0;
transition: 350ms;
z-index: 10;
`;
const SideBar = () => {
  useLocation();
	const userInfo: any = jwt_decode(useContext(TokenContext));
  const [sideBarList] = 
    useState<Array<any>>(userInfo.username.replace('-', '') === 
      "TELON" || userInfo.roles[0] === "ROLE_ADMIN" ? SidebarDataAdmin 
        : userInfo.roles.includes('ROLE_SUPERVISEUR') ? SideBarSuperviseur :userInfo.roles.includes('CLIENT_DAVID')?SideBarVeto : SidebarData)
  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="logo">
          <Link to="/" className="simple-text">
          {userInfo.username}
          </Link>
        </div>
        <SidebarNav>
          <SidebarWrap>
            {sideBarList.map((item, index) => {
              return<SubMenu item={item} key={index} allowFrontRoute={userInfo.user_configurations.ALLOWED_FRONT_ROUTE} roleUser={userInfo.roles[0]} />
            })}
          </SidebarWrap>
        </SidebarNav>
      </div>
    </div>
  );
};

export default SideBar;
